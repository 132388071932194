import {
    CREATE_STAFF_REQ_REQUEST,
    CREATE_STAFF_REQ_SUCCESS,
    CREATE_STAFF_REQ_FAIL,
    READ_STAFF_REQ_REQUEST,
    READ_STAFF_REQ_SUCCESS,
    READ_STAFF_REQ_FAIL,
    UPDATE_STAFF_REQ_REQUEST,
    UPDATE_STAFF_REQ_SUCCESS,
    UPDATE_STAFF_REQ_FAIL,
    CREATE_APPLY_REQUEST,
    CREATE_APPLY_SUCCESS,
    CREATE_APPLY_FAIL,
    READ_APPLY_REQUEST,
    READ_APPLY_SUCCESS,
    READ_APPLY_FAIL,
    UPDATE_APPLY_REQUEST,
    UPDATE_APPLY_SUCCESS,
    UPDATE_APPLY_FAIL,
    DELETE_APPLY_REQUEST,
    DELETE_APPLY_SUCCESS,
    DELETE_APPLY_FAIL,
    CREATE_APP_NOTE_REQUEST,
    CREATE_APP_NOTE_SUCCESS,
    CREATE_APP_NOTE_FAIL,
    READ_APP_NOTE_REQUEST,
    READ_APP_NOTE_SUCCESS,
    READ_APP_NOTE_FAIL,

    CREATE_CAMPAIGN_REQUEST,
    CREATE_CAMPAIGN_SUCCESS,
    CREATE_CAMPAIGN_FAIL,
    READ_CAMPAIGN_REQUEST,
    READ_CAMPAIGN_SUCCESS,
    READ_CAMPAIGN_FAIL,
    UPDATE_CAMPAIGN_REQUEST,
    UPDATE_CAMPAIGN_SUCCESS,
    UPDATE_CAMPAIGN_FAIL,

    CREATE_APPLICANT_MSG_REQUEST,
    CREATE_APPLICANT_MSG_SUCCESS,
    CREATE_APPLICANT_MSG_FAIL,
    READ_APPLICANT_MSG_REQUEST,
    READ_APPLICANT_MSG_SUCCESS,
    READ_APPLICANT_MSG_FAIL
} from "./constants"

import { load, create, update, _delete, defaultFail, defaultStart, defaultSuccess } from "../AsyncStatuses"
import { compareInt, isIterable } from "../../helpers"

const Staff = {
    staff_requests: {
        requests: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
    },
    applicant_notes: {
        notes: [],
        ...load.idle,
        ...create.idle,
    },
    applicant_messages: {
        messages: [],
        ...load.idle,
        ...create.idle,
    },
    campaign: {
        campaigns: [],
        ...create.idle,
        ...load.idle,
        ...update.idle,
    },
    _updated_id: null,
    request_applications: {
        applications: [],
        request_applicants_list: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    }
}

const StaffReducer = (state = Staff, action) => {
    let newState = Object.assign({}, state)
    let newRequests = isIterable(newState?.staff_requests?.requests) ? [...newState?.staff_requests?.requests] : []
    let newNotes = isIterable(newState?.applicant_notes?.notes) ? [...newState?.applicant_notes?.notes] : []
    let newCamp = isIterable(newState?.campaign?.campaigns) ? [...newState?.campaign?.campaigns] : []
    let newApplications = isIterable(newState?.request_applications?.applications) ? [...newState?.request_applications?.applications] : []
    switch (action.type) {
        case CREATE_APPLICANT_MSG_REQUEST:
            return {
                ...newState,
                applicant_messages: defaultStart(newState.applicant_messages, create.start)
            }
        case CREATE_APPLICANT_MSG_SUCCESS:
            return {
                ...newState,
                applicant_messages: defaultSuccess(newState.applicant_messages, create.success, "messages", action.payload.messages)
            }
        case CREATE_APPLICANT_MSG_FAIL:
            return {
                ...newState,
                applicant_messages: defaultFail(newState.applicant_messages, create.error(action.payload?.message))
            }
        case READ_APPLICANT_MSG_REQUEST:
            return {
                ...newState,
                applicant_messages: defaultStart(newState.applicant_messages, load.start)
            }
        case READ_APPLICANT_MSG_SUCCESS:
            if (action.payload?.messages === undefined) return newState
            return {
                ...newState,
                applicant_messages: defaultSuccess(newState.applicant_messages, load.success, "messages", action.payload?.messages)
            }
        case READ_APPLICANT_MSG_FAIL:
            return {
                ...newState,
                applicant_messages: defaultStart(newState.applicant_messages, load.error(action.payload?.message))
            }
        case CREATE_CAMPAIGN_REQUEST:
            return {
                ...newState,
                campaign: defaultStart(newState.campaign, create.start)
            }
        case CREATE_CAMPAIGN_SUCCESS:
            return {
                ...newState,
                campaign: defaultSuccess(newState.campaign, create.success, "campaigns", [...newCamp, action.payload.campaign])
            }
        case CREATE_CAMPAIGN_FAIL:
            return {
                ...newState,
                campaign: defaultFail(newState.campaign, create.error(action.payload?.message))
            }
        case READ_CAMPAIGN_REQUEST:
            return {
                ...newState,
                campaign: defaultStart(newState.campaign, load.start)
            }
        case READ_CAMPAIGN_SUCCESS:
            return {
                ...newState,
                campaign: defaultSuccess(newState.campaign, load.success, "campaigns", action.payload.campaigns)
            }
        case READ_CAMPAIGN_FAIL:
            return {
                ...newState,
                campaign: defaultFail(newState.campaign, load.error(action.payload?.message))
            }
        case UPDATE_CAMPAIGN_REQUEST:
            return {
                ...newState,
                campaign: defaultStart(newState.campaign, update.start)
            }
        case UPDATE_CAMPAIGN_SUCCESS:
            return {
                ...newState,
                campaign: defaultSuccess(newState.campaign, update.success, "campaigns", [...newCamp.filter(c => c._id !== action.payload?.campaign?._id), action.payload?.campaign])
            }
        case UPDATE_CAMPAIGN_FAIL:
            return {
                ...newState,
                campaign: defaultFail(newState.campaign, update.error(action.payload?.message))
            }
        case CREATE_APP_NOTE_REQUEST:
            return {
                ...newState,
                applicant_notes: defaultStart(newState.applicant_notes, create.start)
            }
        case CREATE_APP_NOTE_SUCCESS:
            return {
                ...newState,
                applicant_notes: defaultSuccess(newState.applicant_notes, create.success, "notes", [...newNotes, action.payload.note])
            }
        case CREATE_APP_NOTE_FAIL:
            return {
                ...newState,
                applicant_notes: defaultFail(newState.applicant_notes, create.error(action.payload?.message))
            }
        case READ_APP_NOTE_REQUEST:
            return {
                ...newState,
                applicant_notes: defaultStart(newState.applicant_notes, load.start)
            }
        case READ_APP_NOTE_SUCCESS:
            if (action.payload?.notes === undefined) return newState
            return {
                ...newState,
                applicant_notes: defaultSuccess(newState.applicant_notes, load.success, "notes", action.payload?.notes)
            }
        case READ_APP_NOTE_FAIL:
            return {
                ...newState,
                applicant_notes: defaultStart(newState.applicant_notes, load.error(action.payload?.message))
            }
        case CREATE_STAFF_REQ_REQUEST:
            return {
                ...newState,
                staff_requests: defaultStart(newState.staff_requests, create.start)
            }
        case CREATE_STAFF_REQ_SUCCESS:
            return {
                ...newState,
                staff_requests: defaultSuccess(newState.staff_requests, create.success, "requests", [...newRequests, action.payload.request])
            }
        case CREATE_STAFF_REQ_FAIL:
            return {
                ...newState,
                staff_requests: defaultFail(newState.staff_requests, create.error(action.payload?.message))
            }
        case READ_STAFF_REQ_REQUEST:
            return {
                ...newState,
                staff_requests: defaultStart(newState.staff_requests, load.start)
            }
        case READ_STAFF_REQ_SUCCESS:
            return {
                ...newState,
                staff_requests: defaultSuccess(newState.staff_requests, load.success, "requests", action.payload?.requests)
            }
        case READ_STAFF_REQ_FAIL:
            return {
                ...newState,
                staff_requests: defaultFail(newState.staff_requests, load.error(action.payload?.message))
            }
        case UPDATE_STAFF_REQ_REQUEST:
            return {
                ...newState,
                staff_requests: defaultStart(newState.staff_requests, update.start)
            }
        case UPDATE_STAFF_REQ_SUCCESS:
            return {
                ...newState,
                staff_requests: defaultSuccess(newState.staff_requests, update.success, "requests", [...newRequests.filter(request => compareInt(request._id, action.payload?.request?._id) === false), action.payload?.request])
            }
        case UPDATE_STAFF_REQ_FAIL:
            return {
                ...newState,
                staff_requests: defaultFail(newState.staff_requests, update.error(action.payload?.message))
            }
        case CREATE_APPLY_REQUEST:
            return {
                ...newState,
                request_applications: defaultStart(newState.request_applications, create.start)
            }
        case CREATE_APPLY_SUCCESS:
            return {
                ...newState,
                request_applications: defaultSuccess(newState.request_applications, create.success, "applications", [...newApplications, action.payload.application])
            }
        case CREATE_APPLY_FAIL:
            return {
                ...newState,
                request_applications: defaultFail(newState.request_applications, create.error(action.payload?.message))
            }
        case READ_APPLY_REQUEST:
            return {
                ...newState,
                request_applications: defaultStart(newState.request_applications, load.start)
            }
        case READ_APPLY_SUCCESS:
            return {
                ...newState,
                request_applications: {
                    ...defaultSuccess(newState.request_applications, load.success, "applications", action.payload?.applications),
                    request_applicants_list: action.payload?.request_applicants_list
                }
            }
        case READ_APPLY_FAIL:
            return {
                ...newState,
                request_applications: defaultFail(newState.request_applications, load.error(action.payload?.message))
            }
        case UPDATE_APPLY_REQUEST:
            return {
                ...newState,
                request_applications: defaultStart(newState.request_applications, update.start),
                _updated_id: action.payload
            }
        case UPDATE_APPLY_SUCCESS:
            return {
                ...newState,
                request_applications: defaultSuccess(newState.request_applications, update.success, "applications", [...newApplications.filter(application => compareInt(application._id, action.payload?.application?._id) === false), action.payload?.application]),
                _updated_id: action.payload?.application?._id
            }
        case UPDATE_APPLY_FAIL:
            return {
                ...newState,
                request_applications: defaultFail(newState.request_applications, update.error(action.payload?.message))
            }
        case DELETE_APPLY_REQUEST:
            return {
                ...newState,
                request_applications: defaultStart(newState.request_applications, _delete.start)
            }
        case DELETE_APPLY_SUCCESS:
            return {
                ...newState,
                request_applications: defaultSuccess(newState.request_applications, _delete.success, "applications", newApplications.filter(application => compareInt(application._id, action.payload?.deleted_id) === false))
            }
        case DELETE_APPLY_FAIL:
            return {
                ...newState,
                request_applications: defaultFail(newState.request_applications, _delete.error(action.payload?.message))
            }
        default:
            return { ...state }
    }
}

export default StaffReducer