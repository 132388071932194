import {
    CREATE_TRAVEL_REQUEST,
    CREATE_TRAVEL_SUCCESS,
    CREATE_TRAVEL_FAIL,
    GET_TRAVEL_REQUEST,
    GET_TRAVEL_SUCCESS,
    GET_TRAVEL_FAIL,
    UPDATE_TRAVEL_REQUEST,
    UPDATE_TRAVEL_SUCCESS,
    UPDATE_TRAVEL_FAIL,
    DELETE_TRAVEL_REQUEST,
    DELETE_TRAVEL_SUCCESS,
    DELETE_TRAVEL_FAIL,
} from "./constants"

const TravelReducer = {
    _travel: {
        _travels: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: "",

        deleting: false,
        deleted: false,
        deleteError: ""
    }
}

const TravelsReducer = (state = TravelReducer, action) => {
    let newState = Object.assign({}, state)
    let newTravels = []
    switch (action.type) {
        case CREATE_TRAVEL_REQUEST:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    created: false,
                    creating: true,
                    createError: ""
                }
            }
            return newState
        case CREATE_TRAVEL_SUCCESS:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    created: true,
                    creating: false,
                    createError: "",
                    _travels: newState._travel._travels === undefined ? [action.payload._travel] : [...newState._travel._travels, action.payload._travel]
                }
            }
            return newState
        case CREATE_TRAVEL_FAIL:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    created: false,
                    creating: false,
                    createError: action.payload.message
                }
            }
            return newState
        case GET_TRAVEL_REQUEST:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    loaded: false,
                    loading: true,
                    loadError: ""
                }
            }
            return newState
        case GET_TRAVEL_SUCCESS:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    loaded: true,
                    loading: false,
                    loadError: "",
                    _travels: action.payload._travels
                }
            }
            return newState
        case GET_TRAVEL_FAIL:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    loaded: false,
                    loading: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case UPDATE_TRAVEL_REQUEST:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    updated: false,
                    updating: true,
                    updateError: ""
                }
            }
            return newState
        case UPDATE_TRAVEL_SUCCESS:
            newTravels = newState._travel._travels.filter(_c => parseInt(_c._id) !== parseInt(action.payload._travel._id))
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    updated: true,
                    updating: false,
                    updateError: "",
                    _travels: [...newTravels, action.payload._travel]
                }
            }
            return newState
        case UPDATE_TRAVEL_FAIL:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    updated: false,
                    updating: false,
                    updateError: action.payload.message,
                }
            }
            return newState
        case DELETE_TRAVEL_REQUEST:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    deleting: true,
                    deleted: false,
                    updateError: "",
                }
            }
            return newState
        case DELETE_TRAVEL_SUCCESS:
            newTravels = newState._travel._travels.filter(c => parseInt(c._id) !== parseInt(action.payload._id))
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    deleting: false,
                    deleted: true,
                    updateError: "",
                    _travels: newTravels
                }
            }
            return newState
        case DELETE_TRAVEL_FAIL:
            newState = {
                ...newState,
                _travel: {
                    ...newState._travel,
                    deleting: false,
                    deleted: false,
                    updateError: action.payload.message,
                }
            }
            return newState
        default:
            return { ...state };
    }
}

export default TravelsReducer