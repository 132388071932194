export const READ_VIDEOS_REQUEST = "READ_VIDEOS_REQUEST"
export const READ_VIDEOS_SUCCESS = "READ_VIDEOS_SUCCESS"
export const READ_VIDEOS_FAIL = "READ_VIDEOS_FAIL"
export const CREATE_VIDEOS_REQUEST = "CREATE_VIDEOS_REQUEST"
export const CREATE_VIDEOS_SUCCESS = "CREATE_VIDEOS_SUCCESS"
export const CREATE_VIDEOS_FAIL = "CREATE_VIDEOS_FAIL"
export const UPDATE_VIDEOS_REQUEST = "UPDATE_VIDEOS_REQUEST"
export const UPDATE_VIDEOS_SUCCESS = "UPDATE_VIDEOS_SUCCESS"
export const UPDATE_VIDEOS_FAIL = "UPDATE_VIDEOS_FAIL"
export const DELETE_VIDEOS_REQUEST = "DELETE_VIDEOS_REQUEST"
export const DELETE_VIDEOS_SUCCESS = "DELETE_VIDEOS_SUCCESS"
export const DELETE_VIDEOS_FAIL = "DELETE_VIDEOS_FAIL"
export const READ_ASSIGNMENTS_REQUEST = "READ_ASSIGNMENTS_REQUEST"
export const READ_ASSIGNMENTS_SUCCESS = "READ_ASSIGNMENTS_SUCCESS"
export const READ_ASSIGNMENTS_FAIL = "READ_ASSIGNMENTS_FAIL"
export const CREATE_ASSIGNMENTS_REQUEST = "CREATE_ASSIGNMENTS_REQUEST"
export const CREATE_ASSIGNMENTS_SUCCESS = "CREATE_ASSIGNMENTS_SUCCESS"
export const CREATE_ASSIGNMENTS_FAIL = "CREATE_ASSIGNMENTS_FAIL"
export const UPDATE_ASSIGNMENTS_REQUEST = "UPDATE_ASSIGNMENTS_REQUEST"
export const UPDATE_ASSIGNMENTS_SUCCESS = "UPDATE_ASSIGNMENTS_SUCCESS"
export const UPDATE_ASSIGNMENTS_FAIL = "UPDATE_ASSIGNMENTS_FAIL"
export const DELETE_ASSIGNMENTS_REQUEST = "DELETE_ASSIGNMENTS_REQUEST"
export const DELETE_ASSIGNMENTS_SUCCESS = "DELETE_ASSIGNMENTS_SUCCESS"
export const DELETE_ASSIGNMENTS_FAIL = "DELETE_ASSIGNMENTS_FAIL"