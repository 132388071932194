export const CREATE_DOC_REQUEST = "CREATE_DOC_REQUEST"
export const CREATE_DOC_SUCCESS = "CREATE_DOC_SUCCESS"
export const CREATE_DOC_FAIL = "CREATE_DOC_FAIL"
export const READ_DOC_REQUEST = "READ_DOC_REQUEST"
export const READ_DOC_SUCCESS = "READ_DOC_SUCCESS"
export const READ_DOC_FAIL = "READ_DOC_FAIL"
export const UPDATE_DOC_REQUEST = "UPDATE_DOC_REQUEST"
export const UPDATE_DOC_SUCCESS = "UPDATE_DOC_SUCCESS"
export const UPDATE_DOC_FAIL = "UPDATE_DOC_FAIL"
export const DELETE_DOC_REQUEST = "DELETE_DOC_REQUEST"
export const DELETE_DOC_SUCCESS = "DELETE_DOC_SUCCESS"
export const DELETE_DOC_FAIL = "DELETE_DOC_FAIL"
export const CREATE_DOC_TITLE_REQUEST = "CREATE_DOC_TITLE_REQUEST"
export const CREATE_DOC_TITLE_SUCCESS = "CREATE_DOC_TITLE_SUCCESS"
export const CREATE_DOC_TITLE_FAIL = "CREATE_DOC_TITLE_FAIL"
export const READ_DOC_TITLE_REQUEST = "READ_DOC_TITLE_REQUEST"
export const READ_DOC_TITLE_SUCCESS = "READ_DOC_TITLE_SUCCESS"
export const READ_DOC_TITLE_FAIL = "READ_DOC_TITLE_FAIL"
export const UPDATE_DOC_TITLE_REQUEST = "UPDATE_DOC_TITLE_REQUEST"
export const UPDATE_DOC_TITLE_SUCCESS = "UPDATE_DOC_TITLE_SUCCESS"
export const UPDATE_DOC_TITLE_FAIL = "UPDATE_DOC_TITLE_FAIL"
export const CREATE_FOLDER_REQUEST = "CREATE_FOLDER_REQUEST"
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS"
export const CREATE_FOLDER_FAIL = "CREATE_FOLDER_FAIL"
export const READ_FOLDER_REQUEST = "READ_FOLDER_REQUEST"
export const READ_FOLDER_SUCCESS = "READ_FOLDER_SUCCESS"
export const READ_FOLDER_FAIL = "READ_FOLDER_FAIL"
export const UPDATE_FOLDER_REQUEST = "UPDATE_FOLDER_REQUEST"
export const UPDATE_FOLDER_SUCCESS = "UPDATE_FOLDER_SUCCESS"
export const UPDATE_FOLDER_FAIL = "UPDATE_FOLDER_FAIL"
export const DELETE_FOLDER_REQUEST = "DELETE_FOLDER_REQUEST"
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS"
export const DELETE_FOLDER_FAIL = "DELETE_FOLDER_FAIL"