import {
    READ_PRICE_REQUEST,
    READ_PRICE_SUCCESS,
    READ_PRICE_FAILURE,
    CREATE_PRICE_REQUEST,
    CREATE_PRICE_SUCCESS,
    CREATE_PRICE_FAILURE,
    UPDATE_PRICE_REQUEST,
    UPDATE_PRICE_SUCCESS,
    UPDATE_PRICE_FAILURE,
    DELETE_PRICE_REQUEST,
    DELETE_PRICE_SUCCESS,
    DELETE_PRICE_FAILURE,
} from "./constants"


const prices = {
    price: {
        groups: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
        deleteStatus: "",
        deleteMessage: "",
    },
}

const PricesReducer = (state = prices, action) => {
    let groups = []
    switch (action.type) {
        case READ_PRICE_REQUEST:
            return {
                ...state,
                price: {
                    ...state.price,
                    readStatus: "pending",
                    readMessage: "Loading prices... Please wait",
                }
            }
        case READ_PRICE_SUCCESS:
            groups = []
            for (const model of action.payload.models) {
                if (groups[model._group_number] === undefined) groups[model._group_number] = []
                groups[model._group_number].push(model)
            }
            return {
                ...state,
                price: {
                    ...state.price,
                    readStatus: "success",
                    readMessage: "Prices loaded.",
                    groups: groups
                }
            }
        case READ_PRICE_FAILURE:
            return {
                ...state,
                price: {
                    ...state.price,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_PRICE_REQUEST:
            return {
                ...state,
                price: {
                    ...state.price,
                    createStatus: "pending",
                    createMessage: "Creating price... Please wait",
                }
            }
        case CREATE_PRICE_SUCCESS:
            groups = []
            for (const model of action.payload.models) {
                if (groups[model._group_number] === undefined) groups[model._group_number] = []
                groups[model._group_number].push(model)
            }
            return {
                ...state,
                price: {
                    ...state.price,
                    createStatus: "success",
                    createMessage: "Price created.",
                    groups: groups
                }
            }
        case CREATE_PRICE_FAILURE:
            return {
                ...state,
                price: {
                    ...state.price,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_PRICE_REQUEST:
            return {
                ...state,
                price: {
                    ...state.price,
                    updateStatus: "pending",
                    updateMessage: "Updating price... Please wait",
                }
            }
        case UPDATE_PRICE_SUCCESS:
            console.log(action.payload)
            groups = []
            for (const model of action.payload.models) {
                if (groups[model._group_number] === undefined) groups[model._group_number] = []
                groups[model._group_number].push(model)
            }
            return {
                ...state,
                price: {
                    ...state.price,
                    updateStatus: "success",
                    updateMessage: "Price updated.",
                    groups: groups
                }
            }
        case UPDATE_PRICE_FAILURE:
            return {
                ...state,
                price: {
                    ...state.price,
                    updateStatus: "error",
                    updateMessage: action.payload?.message
                }
            }
        case DELETE_PRICE_REQUEST:
            return {
                ...state,
                price: {
                    ...state.price,
                    deleteStatus: "pending",
                    deleteMessage: "Deleting price... Please wait.",
                }
            }
        case DELETE_PRICE_SUCCESS:
            groups[parseInt(action.payload._id)] = null
            return {
                ...state,
                price: {
                    ...state.price,
                    deleteStatus: "success",
                    deleteMessage: "Price deleted.",
                    groups: groups
                }
            }
        case DELETE_PRICE_FAILURE:
            return {
                ...state,
                price: {
                    ...state.price,
                    deleteStatus: "error",
                    deleteMessage: action.payload?.message
                }
            }
        default:
            return { ...state };
    }
}

export default PricesReducer