import { load, create, update, _delete, defaultFail, defaultStart, defaultSuccess } from "../AsyncStatuses"
import { compareInt, isIterable } from "../../helpers"
import {
    CREATE_SHOP_PROD_REQUEST,
    CREATE_SHOP_PROD_SUCCESS,
    CREATE_SHOP_PROD_FAIL,
    READ_SHOP_PROD_REQUEST,
    READ_SHOP_PROD_SUCCESS,
    READ_SHOP_PROD_FAIL,
    UPDATE_SHOP_PROD_REQUEST,
    UPDATE_SHOP_PROD_SUCCESS,
    UPDATE_SHOP_PROD_FAIL,
    DELETE_SHOP_PROD_REQUEST,
    DELETE_SHOP_PROD_SUCCESS,
    DELETE_SHOP_PROD_FAIL,
    CREATE_CART_REQUEST,
    CREATE_CART_SUCCESS,
    CREATE_CART_FAIL,
    READ_CART_REQUEST,
    READ_CART_SUCCESS,
    READ_CART_FAIL,
    UPDATE_CART_REQUEST,
    UPDATE_CART_SUCCESS,
    UPDATE_CART_FAIL,
    DELETE_CART_REQUEST,
    DELETE_CART_SUCCESS,
    DELETE_CART_FAIL,
    READ_ORDER_HIST_REQUEST,
    READ_ORDER_HIST_SUCCESS,
    READ_ORDER_HIST_FAIL,
    DELETE_ORDER_HIST_REQUEST,
    DELETE_ORDER_HIST_SUCCESS,
    DELETE_ORDER_HIST_FAIL,
    READ_ORDERS_REQUEST,
    READ_ORDERS_SUCCESS,
    READ_ORDERS_FAIL,
} from "./constants"

const Shop = {
    product: {
        products: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    },
    cart: {
        carts: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    },
    order: {
        orders: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    },
    history: {
        orderHistory: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    }
}


const ShopReducer = (state = Shop, action) => {
    let newState = Object.assign({}, state)
    let newProducts = isIterable(newState?.product?.products) ? [...newState?.product?.products] : []
    let newCarts = isIterable(newState?.cart?.carts) ? [...newState.cart.carts] : []
    let newOrders = isIterable(newState?.order?.orders) ? [...newState.order.orders] : []
    let newHistory = isIterable(newState?.history?.orderHistory) ? [...newState.history.orderHistory] : []
    switch (action.type) {
        case CREATE_SHOP_PROD_REQUEST:
            return {
                ...newState,
                product: defaultStart(newState.product, create.start)
            }
        case CREATE_SHOP_PROD_SUCCESS:
            return {
                ...newState,
                product: defaultSuccess(newState.product, create.success, "products", [...newProducts, action.payload.product])
            }
        case CREATE_SHOP_PROD_FAIL:
            return {
                ...newState,
                product: defaultFail(newState.product, create.error(action.payload?.message))
            }
        case READ_SHOP_PROD_REQUEST:
            return {
                ...newState,
                product: defaultStart(newState.product, load.start)
            }
        case READ_SHOP_PROD_SUCCESS:
            return {
                ...newState,
                product: defaultSuccess(newState.product, load.success, "products", action.payload?.products)
            }
        case READ_SHOP_PROD_FAIL:
            return {
                ...newState,
                product: defaultFail(newState.product, load.error(action.payload?.message))
            }
        case UPDATE_SHOP_PROD_REQUEST:
            return {
                ...newState,
                product: defaultStart(newState.product, update.start)
            }
        case UPDATE_SHOP_PROD_SUCCESS:
            return {
                ...newState,
                product: defaultSuccess(newState.product, update.success, "products", [...newProducts.filter(product => compareInt(product._id, action.payload?.product?._id) === false), action.payload?.product])
            }
        case UPDATE_SHOP_PROD_FAIL:
            return {
                ...newState,
                product: defaultFail(newState.product, update.error(action.payload?.message))
            }
        case DELETE_SHOP_PROD_REQUEST:
            return {
                ...newState,
                product: defaultStart(newState.product, _delete.start)
            }
        case DELETE_SHOP_PROD_SUCCESS:
            return {
                ...newState,
                product: defaultSuccess(newState.product, _delete.success, "products", newProducts.filter(product => compareInt(product._id, action.payload?._product_id) === false))
            }
        case DELETE_SHOP_PROD_FAIL:
            return {
                ...newState,
                product: defaultFail(newState.product, _delete.error(action.payload?.message))
            }
        case CREATE_CART_REQUEST:
            return {
                ...newState,
                cart: defaultStart(newState.cart, create.start)
            }
        case CREATE_CART_SUCCESS:
            return {
                ...newState,
                cart: defaultSuccess(newState.cart, create.success, "carts", [...newCarts, action.payload?.cart]),
                order: defaultSuccess(newState.order, create.success, "orders", [...newOrders, ...action.payload?.orders]),
                history: defaultSuccess(newState.history, create.success, "orderHistory", [...newHistory, action.payload?.history])
            }
        case CREATE_CART_FAIL:
            return {
                ...newState,
                cart: defaultFail(newState.cart, create.error(action.payload?.message))
            }
        case READ_CART_REQUEST:
            return {
                ...newState,
                cart: defaultSuccess(newState.cart, load.start)
            }
        case READ_CART_SUCCESS:
            return {
                ...newState,
                cart: defaultSuccess(newState.cart, load.success, "carts", action.payload?.carts),
            }
        case READ_CART_FAIL:
            return {
                ...newState,
                cart: defaultFail(newState.cart, load.error(action.payload?.message))
            }
        case UPDATE_CART_REQUEST:
            return {
                ...newState,
                cart: defaultStart(newState.cart, update.start)
            }
        case UPDATE_CART_SUCCESS:
            return {
                ...newState,
                cart: defaultSuccess(newState.cart, update.success, "carts", [...newCarts.filter(cart => compareInt(cart._id, action.payload?.cart?._id) === false), action.payload?.cart]),
                order: defaultSuccess(newState.order, update.success, "orders", [...newOrders.filter(order => action.payload?.orders.map(order => parseInt(order._id)).includes(parseInt(order._id)) === false), ...action.payload?.orders]),
                history: defaultSuccess(newState.history, update.success, "orderHistory", [...newHistory, action.payload?.history]),
                product: {
                    ...newState.product,
                    products: action.payload.products
                }
            }
        case UPDATE_CART_FAIL:
            return {
                ...newState,
                cart: defaultFail(newState.cart, update.error(action.payload?.message))
            }
        case DELETE_CART_REQUEST:
            return {
                ...newState,
                cart: defaultStart(newState.cart, _delete.start)
            }
        case DELETE_CART_SUCCESS:
            return {
                ...newState,
                cart: defaultSuccess(newState.cart, _delete.success, "carts", newCarts.filter(cart => compareInt(cart._id, action.payload?.cart?._id) === false)),
            }
        case DELETE_CART_FAIL:
            return {
                ...newState,
                cart: defaultFail(newState.cart, _delete.error(action.payload?.message))
            }
        case READ_ORDER_HIST_REQUEST:
            return {
                ...newState,
                history: defaultStart(newState.history, load.start)
            }
        case READ_ORDER_HIST_SUCCESS:
            return {
                ...newState,
                history: defaultSuccess(newState.history, load.success, "orderHistory", action.payload?.history)
            }
        case READ_ORDER_HIST_FAIL:
            return {
                ...newState,
                history: defaultFail(newState.history, load.error(action.payload?.message))
            }
        case DELETE_ORDER_HIST_REQUEST:
            return {
                ...newState,
                history: defaultStart(newState.history, _delete.start)
            }
        case DELETE_ORDER_HIST_SUCCESS:
            return {
                ...newState,
                history: defaultSuccess(newState.history, _delete.success, "orderHistory", newState.history.orderHistory.filter(hist => compareInt(hist._id, action.payload.deleted_id) === false)),
                cart: defaultSuccess(newState.cart, update.success, "carts", [...newState.cart.carts.filter(c => compareInt(c._id, action.payload.new_cart._id) === false), action.payload.new_cart]),
                product: {
                    ...newState.product,
                    products: action.payload.products
                }
            }
        case DELETE_ORDER_HIST_FAIL:
            return {
                ...newState,
                history: defaultFail(newState.history, _delete.error(action.payload?.message))
            }
        case READ_ORDERS_REQUEST:
            return {
                ...newState,
                order: defaultStart(newState.order, load.start)
            }
        case READ_ORDERS_SUCCESS:
            return {
                ...newState,
                order: defaultSuccess(newState.order, load.success, "orders", action.payload?.orders)
            }
        case READ_ORDERS_FAIL:
            return {
                ...newState,
                order: defaultFail(newState.order, load.error(action.payload?.message))
            }
        default:
            return { ...state }
    }
}

export default ShopReducer