export const CREATE_LEAVE_REQUEST = "CREATE_LEAVE_REQUEST"
export const CREATE_LEAVE_SUCCESS = "CREATE_LEAVE_SUCCESS"
export const CREATE_LEAVE_FAIL = "CREATE_LEAVE_FAIL"
export const READ_LEAVE_REQUEST = "READ_LEAVE_REQUEST"
export const READ_LEAVE_SUCCESS = "READ_LEAVE_SUCCESS"
export const READ_LEAVE_FAIL = "READ_LEAVE_FAIL"
export const UPDATE_LEAVE_REQUEST = "UPDATE_LEAVE_REQUEST"
export const UPDATE_LEAVE_SUCCESS = "UPDATE_LEAVE_SUCCESS"
export const UPDATE_LEAVE_FAIL = "UPDATE_LEAVE_FAIL"
export const CREATE_HOLIDAY_REQUEST = "CREATE_HOLIDAY_REQUEST"
export const CREATE_HOLIDAY_SUCCESS = "CREATE_HOLIDAY_SUCCESS"
export const CREATE_HOLIDAY_FAILURE = "CREATE_HOLIDAY_FAILURE"
export const READ_HOLIDAY_REQUEST = "READ_HOLIDAY_REQUEST"
export const READ_HOLIDAY_SUCCESS = "READ_HOLIDAY_SUCCESS"
export const READ_HOLIDAY_FAILURE = "READ_HOLIDAY_FAILURE"
export const UPDATE_HOLIDAY_REQUEST = "UPDATE_HOLIDAY_REQUEST"
export const UPDATE_HOLIDAY_SUCCESS = "UPDATE_HOLIDAY_SUCCESS"
export const UPDATE_HOLIDAY_FAILURE = "UPDATE_HOLIDAY_FAILURE"
export const DELETE_HOLIDAY_REQUEST = "DELETE_HOLIDAY_REQUEST"
export const DELETE_HOLIDAY_SUCCESS = "DELETE_HOLIDAY_SUCCESS"
export const DELETE_HOLIDAY_FAILURE = "DELETE_HOLIDAY_FAILURE"