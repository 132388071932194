import { load, create, update, _delete, defaultFail, defaultStart, defaultSuccess } from "../AsyncStatuses"
import { compareInt } from "../../helpers"
import {
    CREATE_ASSET_REQUEST,
    CREATE_ASSET_SUCCESS,
    CREATE_ASSET_FAIL,
    READ_ASSET_REQUEST,
    READ_ASSET_SUCCESS,
    READ_ASSET_FAIL,
    UPDATE_ASSET_REQUEST,
    UPDATE_ASSET_SUCCESS,
    UPDATE_ASSET_FAIL,
    DELETE_ASSET_REQUEST,
    DELETE_ASSET_SUCCESS,
    DELETE_ASSET_FAIL,
    CREATE_SERVICERS_REQUEST,
    CREATE_SERVICERS_SUCCESS,
    CREATE_SERVICERS_FAIL,
    READ_SERVICERS_REQUEST,
    READ_SERVICERS_SUCCESS,
    READ_SERVICERS_FAIL,
    UPDATE_SERVICERS_REQUEST,
    UPDATE_SERVICERS_SUCCESS,
    UPDATE_SERVICERS_FAIL,
    DELETE_SERVICERS_REQUEST,
    DELETE_SERVICERS_SUCCESS,
    DELETE_SERVICERS_FAIL,
    CREATE_SUPPLIERS_REQUEST,
    CREATE_SUPPLIERS_SUCCESS,
    CREATE_SUPPLIERS_FAIL,
    READ_SUPPLIERS_REQUEST,
    READ_SUPPLIERS_SUCCESS,
    READ_SUPPLIERS_FAIL,
    UPDATE_SUPPLIERS_REQUEST,
    UPDATE_SUPPLIERS_SUCCESS,
    UPDATE_SUPPLIERS_FAIL,
    DELETE_SUPPLIERS_REQUEST,
    DELETE_SUPPLIERS_SUCCESS,
    DELETE_SUPPLIERS_FAIL,
    CREATE_SERV_HIST_REQUEST,
    CREATE_SERV_HIST_SUCCESS,
    CREATE_SERV_HIST_FAIL,
    READ_SERV_HIST_REQUEST,
    READ_SERV_HIST_SUCCESS,
    READ_SERV_HIST_FAIL,
    CREATE_INVOICES_REQUEST,
    CREATE_INVOICES_SUCCESS,
    CREATE_INVOICES_FAIL,
    READ_INVOICES_REQUEST,
    READ_INVOICES_SUCCESS,
    READ_INVOICES_FAIL,
    CREATE_OWNER_HIST_REQUEST,
    CREATE_OWNER_HIST_SUCCESS,
    CREATE_OWNER_HIST_FAIL,
    READ_OWNER_HIST_REQUEST,
    READ_OWNER_HIST_SUCCESS,
    READ_OWNER_HIST_FAIL,
    UPDATE_OWNER_HIST_REQUEST,
    UPDATE_OWNER_HIST_SUCCESS,
    UPDATE_OWNER_HIST_FAIL,
    CREATE_LOC_HIST_REQUEST,
    CREATE_LOC_HIST_SUCCESS,
    CREATE_LOC_HIST_FAIL,
    READ_LOC_HIST_REQUEST,
    READ_LOC_HIST_SUCCESS,
    READ_LOC_HIST_FAIL,
    UPDATE_LOC_HIST_REQUEST,
    UPDATE_LOC_HIST_SUCCESS,
    UPDATE_LOC_HIST_FAIL,
    CREATE_ASSET_CAT_REQUEST,
    CREATE_ASSET_CAT_SUCCESS,
    CREATE_ASSET_CAT_FAIL,
    UPDATE_ASSET_CAT_REQUEST,
    UPDATE_ASSET_CAT_SUCCESS,
    READ_ASSET_CAT_REQUEST,
    READ_ASSET_CAT_SUCCESS,
    READ_ASSET_CAT_FAIL,
    CREATE_ISSUE_REPORT_REQUEST,
    CREATE_ISSUE_REPORT_SUCCESS,
    CREATE_ISSUE_REPORT_FAIL,
    READ_ISSUE_REPORT_REQUEST,
    READ_ISSUE_REPORT_SUCCESS,
    READ_ISSUE_REPORT_FAIL,
    UPDATE_ASSET_CAT_FAIL,
    CREATE_CONFIRMATION_REQUEST,
    CREATE_CONFIRMATION_SUCCESS,
    CREATE_CONFIRMATION_FAIL,
    READ_CONFIRMATION_REQUEST,
    READ_CONFIRMATION_SUCCESS,
    READ_CONFIRMATION_FAIL,
    UPDATE_CONFIRMATION_REQUEST,
    UPDATE_CONFIRMATION_SUCCESS,
    UPDATE_CONFIRMATION_FAIL,
    UPDATE_ISSUE_REPORT_REQUEST,
    UPDATE_ISSUE_REPORT_SUCCESS,
    UPDATE_ISSUE_REPORT_FAIL,
    CREATE_ASSET_KIT_REQUEST,
    CREATE_ASSET_KIT_SUCCESS,
    CREATE_ASSET_KIT_FAILURE,
    READ_ASSET_KIT_REQUEST,
    READ_ASSET_KIT_SUCCESS,
    READ_ASSET_KIT_FAILURE,
    UPDATE_ASSET_KIT_REQUEST,
    UPDATE_ASSET_KIT_SUCCESS,
    UPDATE_ASSET_KIT_FAILURE,
    DELETE_ASSET_KIT_REQUEST,
    DELETE_ASSET_KIT_SUCCESS,
    DELETE_ASSET_KIT_FAILURE,
    COPY_ASSET_KIT_REQUEST,
    COPY_ASSET_KIT_SUCCESS,
    COPY_ASSET_KIT_FAILURE
} from "./constants"


const Assets = {
    asset: {
        assets: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle,
        copy: {},
    },
    supplier: {
        suppliers: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    },
    servicer: {
        servicers: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    },
    history: {
        serviceHistory: [],
        ...load.idle,
        ...create.idle,
    },
    invoice: {
        invoices: [],
        ...load.idle,
        ...create.idle,
    },
    ownerHistory: {
        owners: [],
        ...load.idle,
        ...create.idle,
        ...update.idle
    },
    locationHistory: {
        locations: [],
        ...load.idle,
        ...create.idle,
        ...update.idle
    },
    assetCategories: {
        categories: [],
        ...load.idle,
        ...create.idle,
        ...update.idle
    },
    confirmation: {
        confirmations: [],
        ...load.idle,
        ...create.idle,
        ...update.idle
    },
    issue: {
        issues: [],
        ...load.idle,
        ...create.idle,
        ...update.idle
    },
    kits: {
        kits: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle,
        copyMessage: "idle",
        copyStatus: "idle"
    }
}

const isIterable = obj => {
    if (obj == null) return false
    return typeof obj[Symbol.iterator] === 'function';
}

const AssetReducer = (state = Assets, action) => {
    let newState = Object.assign({}, state)
    let new_kits
    let newAssets = isIterable(newState?.asset?.assets) ? [...newState?.asset?.assets] : []
    let newServicers = isIterable(newState?.servicer?.servicers) ? [...newState.servicer.servicers] : []
    let newSuppliers = isIterable(newState?.supplier?.suppliers) ? [...newState.supplier.suppliers] : []
    let newServiceHistory = isIterable(newState?.history?.serviceHistory) ? [...newState.history.serviceHistory] : []
    let newInvoices = isIterable(newState?.invoice?.invoices) ? [...newState.invoice.invoices] : []
    let newOwners = isIterable(newState?.ownerHistory?.owners) ? [...newState.ownerHistory.owners] : []
    let newLocations = isIterable(newState?.locationHistory?.locations) ? [...newState.locationHistory.locations] : []
    let newCategories = isIterable(newState?.assetCategories?.categories) ? [...newState.assetCategories.categories] : []
    let newconfirmations = isIterable(newState?.confirmation?.confirmations) ? [...newState.confirmation.confirmations] : []
    let newIssues = isIterable(newState?.issue.issues) ? [...newState.issue.issues] : []
    switch (action.type) {
        case "COPY_KIT":
            return {
                ...newState,
                asset: {
                    ...newState.asset,
                    copy: action.payload
                }
            }
        case CREATE_ASSET_REQUEST:
            return {
                ...newState,
                asset: defaultStart(newState.asset, create.start),
            }
        case CREATE_ASSET_SUCCESS:
            if (action.payload?.asset !== undefined)
                return { ...newState, asset: defaultSuccess(newState.asset, create.success, "assets", [...newAssets, action.payload.asset]) }
            else
                return { ...newState, asset: defaultSuccess(newState.asset, create.success, "assets", [...newAssets, ...action.payload.assets]) }
        case CREATE_ASSET_FAIL:
            console.log(action.payload)
            return {
                ...newState,
                asset: defaultFail(newState.asset, create.error(action.payload?.message))
            }
        case READ_ASSET_REQUEST:
            return {
                ...newState,
                asset: defaultStart(newState.asset, load.start)
            }
        case READ_ASSET_SUCCESS:
            return {
                ...newState,
                asset: defaultSuccess(newState.asset, load.success, "assets", action.payload?.assets)
            }
        case READ_ASSET_FAIL:
            return {
                ...newState,
                asset: defaultFail(newState.asset, load.error(action.payload?.message))
            }
        case UPDATE_ASSET_REQUEST:
            return {
                ...newState,
                asset: defaultStart(newState.asset, update.start)
            }
        case UPDATE_ASSET_SUCCESS:
            return {
                ...newState,
                asset: defaultSuccess(newState.asset, update.success, "assets", [...newAssets.filter(asset => compareInt(asset._id, action.payload.asset._id) === false), action.payload.asset])
            }
        case UPDATE_ASSET_FAIL:
            return {
                ...newState,
                asset: defaultFail(newState.asset, update.error(action.payload?.message))
            }
        case DELETE_ASSET_REQUEST:
            return {
                ...newState,
                asset: defaultStart(newState.asset, _delete.start)
            }
        case DELETE_ASSET_SUCCESS:
            return {
                ...newState,
                asset: defaultSuccess(newState.asset, _delete.success, "assets", newAssets.filter(asset => compareInt(asset._id, action.payload.asset._id) === false))
            }
        case DELETE_ASSET_FAIL:
            return {
                ...newState,
                asset: defaultFail(newState.asset, _delete.error(action.payload?.message))
            }
        case CREATE_SERVICERS_REQUEST:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, create.start)
            }
        case CREATE_SERVICERS_SUCCESS:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, create.success, "servicers", [...newServicers, action.payload.servicer])
            }
        case CREATE_SERVICERS_FAIL:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, create.error(action.payload?.message))
            }
        case READ_SERVICERS_REQUEST:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, load.start)
            }
        case READ_SERVICERS_SUCCESS:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, load.success, "servicers", action.payload.servicers)
            }
        case READ_SERVICERS_FAIL:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, load.error(action.payload?.message))
            }
        case UPDATE_SERVICERS_REQUEST:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, update.start)
            }
        case UPDATE_SERVICERS_SUCCESS:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, update.success, "servicers", [...newServicers.filter(servicer => compareInt(servicer._id, action.payload.servicer._id) === false), action.payload.servicer])
            }
        case UPDATE_SERVICERS_FAIL:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, update.error(action.payload?.message))
            }
        case DELETE_SERVICERS_REQUEST:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, _delete.start)
            }
        case DELETE_SERVICERS_SUCCESS:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, _delete.success, "servicers", [newServicers.filter(servicer => compareInt(servicer._id, action.payload.servicer._id) === false)])
            }
        case DELETE_SERVICERS_FAIL:
            return {
                ...newState,
                servicer: defaultSuccess(newState.servicer, _delete.error(action.payload?.message))
            }
        case CREATE_SUPPLIERS_REQUEST:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, create.start)
            }
        case CREATE_SUPPLIERS_SUCCESS:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, create.success, "suppliers", [...newSuppliers, action.payload.supplier])
            }
        case CREATE_SUPPLIERS_FAIL:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, create.error(action.payload?.message))
            }
        case READ_SUPPLIERS_REQUEST:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, load.start)
            }
        case READ_SUPPLIERS_SUCCESS:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, load.success, "suppliers", action.payload.suppliers)
            }
        case READ_SUPPLIERS_FAIL:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, load.error(action.payload?.message))
            }
        case UPDATE_SUPPLIERS_REQUEST:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, update.start)
            }
        case UPDATE_SUPPLIERS_SUCCESS:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, update.success, "suppliers", [...newSuppliers.filter(supplier => compareInt(supplier._id, action.payload.supplier._id) === false), action.payload.supplier])
            }
        case UPDATE_SUPPLIERS_FAIL:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, update.error(action.payload?.message))
            }
        case DELETE_SUPPLIERS_REQUEST:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, _delete.start)
            }
        case DELETE_SUPPLIERS_SUCCESS:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, _delete.success, "suppliers", [newSuppliers.filter(supplier => compareInt(supplier._id, action.payload.supplier._id) === false)])
            }
        case DELETE_SUPPLIERS_FAIL:
            return {
                ...newState,
                supplier: defaultSuccess(newState.supplier, _delete.error(action.payload?.message))
            }
        case CREATE_SERV_HIST_REQUEST:
            return {
                ...newState,
                history: defaultStart(newState.history, create.start),
            }
        case CREATE_SERV_HIST_SUCCESS:
            return {
                ...newState,
                history: defaultSuccess(newState.history, create.success, "serviceHistory", [...newServiceHistory, action.payload.service])
            }
        case CREATE_SERV_HIST_FAIL:
            return {
                ...newState,
                history: defaultFail(newState.history, create.error(action.payload?.message))
            }
        case READ_SERV_HIST_REQUEST:
            return {
                ...newState,
                history: defaultStart(newState.history, load.start)
            }
        case READ_SERV_HIST_SUCCESS:
            return {
                ...newState,
                history: defaultSuccess(newState.history, load.success, "serviceHistory", action.payload.services)
            }
        case READ_SERV_HIST_FAIL:
            return {
                ...newState,
                history: defaultFail(newState.history, load.error(action.payload?.message))
            }
        case CREATE_INVOICES_REQUEST:
            return {
                ...newState,
                invoice: defaultStart(newState.invoice, create.start),
            }
        case CREATE_INVOICES_SUCCESS:
            return {
                ...newState,
                invoice: defaultSuccess(newState.invoice, create.success, "invoices", [...newInvoices, action.payload.invoice])
            }
        case CREATE_INVOICES_FAIL:
            return {
                ...newState,
                invoice: defaultFail(newState.invoice, create.error(action.payload?.message))
            }
        case READ_INVOICES_REQUEST:
            return {
                ...newState,
                invoice: defaultStart(newState.invoice, load.start)
            }
        case READ_INVOICES_SUCCESS:
            return {
                ...newState,
                invoice: defaultSuccess(newState.invoice, load.success, "invoices", action.payload.invoices)
            }
        case READ_INVOICES_FAIL:
            return {
                ...newState,
                invoice: defaultFail(newState.invoice, load.error(action.payload?.message))
            }
        case CREATE_OWNER_HIST_REQUEST:
            return {
                ...newState,
                ownerHistory: defaultStart(newState.ownerHistory, create.start)
            }
        case CREATE_OWNER_HIST_SUCCESS:
            return {
                ...newState,
                ownerHistory: defaultSuccess(newState.ownerHistory, create.success, "owners", [...newOwners, action.payload.history])
            }
        case CREATE_OWNER_HIST_FAIL:
            return {
                ...newState,
                ownerHistory: defaultFail(newState.ownerHistory, create.error(action.payload?.message))
            }
        case READ_OWNER_HIST_REQUEST:
            return {
                ...newState,
                ownerHistory: defaultStart(newState.ownerHistory, load.start)
            }
        case READ_OWNER_HIST_SUCCESS:
            return {
                ...newState,
                ownerHistory: defaultSuccess(newState.ownerHistory, load.success, "owners", action.payload.history)
            }
        case READ_OWNER_HIST_FAIL:
            return {
                ...newState,
                ownerHistory: defaultFail(newState.ownerHistory, load.error(action.payload?.message))
            }

        case UPDATE_OWNER_HIST_REQUEST:
            return {
                ...newState,
                ownerHistory: defaultStart(newState.ownerHistory, update.start)
            }
        case UPDATE_OWNER_HIST_SUCCESS:
            return {
                ...newState,
                ownerHistory: defaultSuccess(newState.ownerHistory, update.success, "owners", [...newOwners.filter(owner => compareInt(owner.id, action.payload.history.id) === false), action.payload.history])
            }
        case UPDATE_OWNER_HIST_FAIL:
            return {
                ...newState,
                ownerHistory: defaultFail(newState.ownerHistory, update.error(action.payload?.message))
            }
        case CREATE_LOC_HIST_REQUEST:
            return {
                ...newState,
                locationHistory: defaultStart(newState.locationHistory, create.start)
            }
        case CREATE_LOC_HIST_SUCCESS:
            return {
                ...newState,
                locationHistory: defaultSuccess(newState.locationHistory, create.success, "locations", [...newLocations, action.payload.location])
            }
        case CREATE_LOC_HIST_FAIL:
            return {
                ...newState,
                locationHistory: defaultFail(newState.locationHistory, create.error(action.payload?.message))
            }
        case READ_LOC_HIST_REQUEST:
            return {
                ...newState,
                locationHistory: defaultStart(newState.locationHistory, load.start)
            }
        case READ_LOC_HIST_SUCCESS:
            return {
                ...newState,
                locationHistory: defaultSuccess(newState.locationHistory, load.success, "locations", action.payload.location)
            }
        case READ_LOC_HIST_FAIL:
            return {
                ...newState,
                locationHistory: defaultFail(newState.locationHistory, load.error(action.payload?.message))
            }
        case UPDATE_LOC_HIST_REQUEST:
            return {
                ...newState,
                locationHistory: defaultStart(newState.locationHistory, update.start)
            }
        case UPDATE_LOC_HIST_SUCCESS:
            return {
                ...newState,
                locationHistory: defaultSuccess(newState.locationHistory, update.success, "locations", [...newLocations.filter(location => compareInt(location._id, action.payload.location._id) === false), action.payload.location])
            }
        case UPDATE_LOC_HIST_FAIL:
            return {
                ...newState,
                locationHistory: defaultFail(newState.locationHistory, update.error(action.payload?.message))
            }
        case CREATE_ASSET_CAT_REQUEST:
            return {
                ...newState,
                assetCategories: defaultStart(newState.assetCategories, create.start)
            }
        case CREATE_ASSET_CAT_SUCCESS:
            return {
                ...newState,
                assetCategories: defaultSuccess(newState.assetCategories, create.success, "categories", [...newCategories, action.payload.category])
            }
        case CREATE_ASSET_CAT_FAIL:
            return {
                ...newState,
                assetCategories: defaultFail(newState.assetCategories, create.error(action.payload?.message))
            }

        case UPDATE_ASSET_CAT_REQUEST:
            return {
                ...newState,
                assetCategories: defaultStart(newState.assetCategories, update.start)
            }
        case UPDATE_ASSET_CAT_SUCCESS:
            return {
                ...newState,
                assetCategories: defaultSuccess(newState.assetCategories, update.success, "categories", [...newCategories.filter(cat => cat._id !== action.payload.category._id), action.payload.category])
            }
        case UPDATE_ASSET_CAT_FAIL:
            return {
                ...newState,
                assetCategories: defaultFail(newState.assetCategories, update.error(action.payload?.message))
            }
        case READ_ASSET_CAT_REQUEST:
            return {
                ...newState,
                assetCategories: defaultStart(newState.assetCategories, load.start)
            }
        case READ_ASSET_CAT_SUCCESS:
            return {
                ...newState,
                assetCategories: defaultSuccess(newState.assetCategories, load.success, "categories", action.payload.category)
            }
        case READ_ASSET_CAT_FAIL:
            return {
                ...newState,
                assetCategories: defaultFail(newState.assetCategories, load.error(action.payload?.message))
            }
        case CREATE_ISSUE_REPORT_REQUEST:
            return {
                ...newState,
                issue: defaultStart(newState.issue, create.start)
            }
        case CREATE_ISSUE_REPORT_SUCCESS:
            return {
                ...newState,
                issue: defaultSuccess(newState.issue, create.success, "issues", [...newIssues, action.payload.issue])
            }
        case CREATE_ISSUE_REPORT_FAIL:
            return {
                ...newState,
                issue: defaultFail(newState.issue, create.error(action.payload?.message))
            }
        case READ_ISSUE_REPORT_REQUEST:
            return {
                ...newState,
                issue: defaultStart(newState.issue, load.start)
            }
        case READ_ISSUE_REPORT_SUCCESS:
            return {
                ...newState,
                issue: defaultSuccess(newState.issue, load.success, "issues", action.payload.issues)
            }
        case READ_ISSUE_REPORT_FAIL:
            return {
                ...newState,
                issue: defaultFail(newState.issue, load.error(action.payload?.message))
            }
        case UPDATE_ISSUE_REPORT_REQUEST:
            return {
                ...newState,
                issue: defaultStart(newState.issue, update.start)
            }
        case UPDATE_ISSUE_REPORT_SUCCESS:
            return {
                ...newState,
                issue: defaultSuccess(newState.issue, update.success, "issues", [...newIssues.filter(issue => issue._id !== action.payload.issue._id), action.payload.issue])
            }
        case UPDATE_ISSUE_REPORT_FAIL:
            return {
                ...newState,
                issue: defaultFail(newState.issue, update.error(action.payload?.message))
            }
        case CREATE_CONFIRMATION_REQUEST:
            return {
                ...newState, confirmation: defaultStart(newState.confirmation, create.start)
            }
        case CREATE_CONFIRMATION_SUCCESS:
            return {
                ...newState, confirmation: defaultSuccess(newState.confirmation, create.success, "confirmations", [...newconfirmations, ...action.payload.confirmations])
            }
        case CREATE_CONFIRMATION_FAIL:
            return {
                ...newState, confirmation: defaultFail(newState.confirmation, create.error(action.payload?.message))
            }

        case UPDATE_CONFIRMATION_REQUEST:
            return {
                ...newState, confirmation: defaultStart(newState.confirmation, update.start)
            }
        case UPDATE_CONFIRMATION_SUCCESS:
            return {
                ...newState, confirmation: defaultSuccess(newState.confirmation, update.success, "confirmations", [...newconfirmations.filter(cat => cat._id !== action.payload.confirmation._id), action.payload.confirmation])
            }
        case UPDATE_CONFIRMATION_FAIL:
            return {
                ...newState, confirmation: defaultFail(newState.confirmation, update.error(action.payload?.message))
            }
        case READ_CONFIRMATION_REQUEST:
            return {
                ...newState, confirmation: defaultStart(newState.confirmation, load.start)
            }
        case READ_CONFIRMATION_SUCCESS:
            return { ...newState, confirmation: defaultSuccess(newState.confirmation, load.success, "confirmations", action.payload.confirmations) }
        case READ_CONFIRMATION_FAIL:
            return { ...newState, confirmation: defaultFail(newState.confirmation, load.error(action.payload?.message)) }
        case CREATE_ASSET_KIT_REQUEST:
            return { ...newState, kits: { ...newState.kits, ...create.start } }
        case CREATE_ASSET_KIT_SUCCESS:
            new_kits = [...newState.kits.kits]
            new_kits[action.payload.kit._id] = action.payload.kit
            return { ...newState, kits: { ...newState.kits, ...create.success, kits: new_kits } }
        case CREATE_ASSET_KIT_FAILURE:
            return { ...newState, kits: { ...newState.kits, ...create.error(action.payload.message) } }
        case READ_ASSET_KIT_REQUEST:
            return { ...newState, kits: { ...newState.kits, ...load.start } }
        case READ_ASSET_KIT_SUCCESS:
            return { ...newState, kits: { ...newState.kits, ...load.success, kits: action.payload.kits } }
        case READ_ASSET_KIT_FAILURE:
            return { ...newState, kits: { ...newState.kits, ...load.error(action.payload.message) } }
        case UPDATE_ASSET_KIT_REQUEST:
            return { ...newState, kits: { ...newState.kits, ...update.start } }
        case UPDATE_ASSET_KIT_SUCCESS:
            newAssets = [...newState.asset.assets]
            for (const a of newAssets) {
                if (action.payload.kit._ids.includes(a._id))
                    a._kit_id = action.payload.kit._id
            }
            new_kits = [...newState.kits.kits]
            new_kits[action.payload.kit._id] = action.payload.kit
            return { ...newState, asset: { ...newState.asset, assets: newAssets }, kits: { ...newState.kits, ...update.success, kits: new_kits } }
        case UPDATE_ASSET_KIT_FAILURE:
            return { ...newState, kits: { ...newState.kits, ...update.error(action.payload.message) } }
        case DELETE_ASSET_KIT_REQUEST:
            return { ...newState, kits: { ...newState.kits, ..._delete.start } }
        case DELETE_ASSET_KIT_SUCCESS:
            new_kits = [...newState.kits.kits]
            new_kits[action.payload._kit_id] = null
            return { ...newState, kits: { ...newState.kits, ..._delete.success, kits: new_kits } }
        case DELETE_ASSET_KIT_FAILURE:
            return { ...newState, kits: { ...newState.kits, ..._delete.error(action.payload.message) } }
        case COPY_ASSET_KIT_REQUEST:
            return {
                ...newState,
                kits: {
                    ...newState.kits,
                    copyMessage: "Copying asset kit. This may take some time, please wait...",
                    copyStatus: "pending"
                }
            }
        case COPY_ASSET_KIT_SUCCESS:
            new_kits = [...newState.kits.kits]
            new_kits[action.payload.kit._id] = action.payload.kit
            return {
                ...newState,
                kits: {
                    ...newState.kits,
                    kits: new_kits,
                    copyMessage: "New asset kit created. Go back to the kits table to review.",
                    copyStatus: "success"
                },
                asset: {
                    ...newState.asset,
                    assets: [...newState.asset.assets, ...action.payload.assets]
                }
            }
        case COPY_ASSET_KIT_FAILURE:
            return {
                ...newState,
                kits: {
                    ...newState.kits,
                    copyMessage: "An error occured. Please capture and contact support. ",
                    copyStatus: "error"
                }
            }
        default:
            return { ...state }
    }
}

export default AssetReducer