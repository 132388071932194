import {
    READ_STATES_REQUEST,
    READ_STATES_SUCCESS,
    READ_STATES_FAIL,
    CREATE_STATES_REQUEST,
    CREATE_STATES_SUCCESS,
    CREATE_STATES_FAIL,
    UPDATE_STATES_REQUEST,
    UPDATE_STATES_SUCCESS,
    UPDATE_STATES_FAIL,
    DELETE_STATES_REQUEST,
    DELETE_STATES_SUCCESS,
    DELETE_STATES_FAIL,
    GET_GENERAL_REQUEST,
    GET_GENERAL_SUCCESS,
    GET_GENERAL_FAIL
} from "./constants"

import { load, create, update, _delete } from "../AsyncStatuses"


const general = {
    _n_job_titles: [],
    _n_statuses: [],
    _n_makes: [],
    _n_models: [],
    _n_parts: [],
    _n_project_categories: [],
    n_settings: {},

    _user_types: [],
    _statuses: [],
    _makes: [],
    _models: [],
    _parts: [],
    _body_types: [],
    _phone_codes: [],
    _timezones: [],
    _decline_reasons: [],
    _cancel_reasons: [],
    _pause_reasons: [],
    _product_categories: [],
    _order_statuses: [],
    _job_titles: [],
    _project_categories: [],
    _credit_reasons: [],
    _staff_requests_statuses: [],
    _applicants_statuses: [],
    _site_settings: [],
    _training_categories: [],
    _isos: [],
    _iso_types: [],
    _rnd_parts: [],
    loading: false,
    loaded: false,
    error: "",


    _states: [],
    _regions: [],
    statesStauses: {
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    }
}

const GeneralReducer = (state = general, action) => {
    let newState = Object.assign({}, state)
    switch (action.type) {
        case GET_GENERAL_REQUEST:
            return {
                ...state,
                loading: true,
                loaded: false
            }
        case GET_GENERAL_SUCCESS: {
            const n_job_titles = []
            const n_statuses = []
            const n_makes = []
            const n_models = []
            const n_parts = []
            const n_project_categories = []
            const n_settings = {}


            for (const job_title of action.payload._job_titles) { n_job_titles[job_title._id] = job_title }
            for (const statuse of action.payload._statuses) { n_statuses[statuse._id] = statuse }
            for (const make of action.payload._makes) { n_makes[make._id] = make }
            for (const model of action.payload._models) { n_models[model._id] = model }
            for (const part of action.payload._parts) { n_parts[part._id] = part }
            for (const project_categorie of action.payload._project_categories) { n_project_categories[project_categorie._id] = project_categorie }
            for (const setting of action.payload._site_settings) { n_settings[setting._name] = setting }

            return {
                ...state,
                loading: false,
                loaded: true,
                ...action.payload,
                _parts: action.payload._parts.filter(p => p._show_in_jobs === 1),
                _rnd_parts: action.payload._parts.filter(p => p._show_in_rnd === 1),
                _n_job_titles: n_job_titles,
                n_settings: n_settings,
                _n_statuses: n_statuses,
                _n_makes: n_makes,
                _n_models: n_models,
                _n_parts: n_parts,
                _n_project_categories: n_project_categories,
                error: ""
            }
        }
        case GET_GENERAL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload.message
            }
        case CREATE_STATES_REQUEST:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ...create.start }
            }
        case CREATE_STATES_SUCCESS:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ...create.success },
                _states: action.payload.states
            }
        case CREATE_STATES_FAIL:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ...create.error(action.payload?.message) }
            }
        case READ_STATES_REQUEST:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ...load.start }
            }
        case READ_STATES_SUCCESS:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ...load.success },
                _states: action.payload.states,
                _regions: action.payload.regions
            }
        case READ_STATES_FAIL:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ...load.error(action.payload?.message) }
            }
        case UPDATE_STATES_REQUEST:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ...update.start }
            }
        case UPDATE_STATES_SUCCESS:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ...update.success },
                _states: action.payload.states,
                _regions: action.payload.regions
            }
        case UPDATE_STATES_FAIL:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ...update.error(action.payload?.message) }
            }
        case DELETE_STATES_REQUEST:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ..._delete.start }
            }
        case DELETE_STATES_SUCCESS:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ..._delete.success },
                _states: action.payload.states,
                _regions: action.payload.regions
            }
        case DELETE_STATES_FAIL:
            return {
                ...newState,
                statesStauses: { ...newState.statesStauses, ..._delete.error(action.payload?.message) }
            }
        default:
            return { ...state };
    }
}

export default GeneralReducer