export const CREATE_SHOP_PROD_REQUEST = "CREATE_SHOP_PROD_REQUEST"
export const CREATE_SHOP_PROD_SUCCESS = "CREATE_SHOP_PROD_SUCCESS"
export const CREATE_SHOP_PROD_FAIL = "CREATE_SHOP_PROD_FAIL"
export const READ_SHOP_PROD_REQUEST = "READ_SHOP_PROD_REQUEST"
export const READ_SHOP_PROD_SUCCESS = "READ_SHOP_PROD_SUCCESS"
export const READ_SHOP_PROD_FAIL = "READ_SHOP_PROD_FAIL"
export const UPDATE_SHOP_PROD_REQUEST = "UPDATE_SHOP_PROD_REQUEST"
export const UPDATE_SHOP_PROD_SUCCESS = "UPDATE_SHOP_PROD_SUCCESS"
export const UPDATE_SHOP_PROD_FAIL = "UPDATE_SHOP_PROD_FAIL"
export const DELETE_SHOP_PROD_REQUEST = "DELETE_SHOP_PROD_REQUEST"
export const DELETE_SHOP_PROD_SUCCESS = "DELETE_SHOP_PROD_SUCCESS"
export const DELETE_SHOP_PROD_FAIL = "DELETE_SHOP_PROD_FAIL"
export const CREATE_CART_REQUEST = "CREATE_CART_REQUEST"
export const CREATE_CART_SUCCESS = "CREATE_CART_SUCCESS"
export const CREATE_CART_FAIL = "CREATE_CART_FAIL"
export const READ_CART_REQUEST = "READ_CART_REQUEST"
export const READ_CART_SUCCESS = "READ_CART_SUCCESS"
export const READ_CART_FAIL = "READ_CART_FAIL"
export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST"
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS"
export const UPDATE_CART_FAIL = "UPDATE_CART_FAIL"
export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST"
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS"
export const DELETE_CART_FAIL = "DELETE_CART_FAIL"
export const READ_ORDER_HIST_REQUEST = "READ_ORDER_HIST_REQUEST"
export const READ_ORDER_HIST_SUCCESS = "READ_ORDER_HIST_SUCCESS"
export const READ_ORDER_HIST_FAIL = "READ_ORDER_HIST_FAIL"
export const READ_ORDERS_REQUEST = "READ_ORDERS_REQUEST"
export const READ_ORDERS_SUCCESS = "READ_ORDERS_SUCCESS"
export const READ_ORDERS_FAIL = "READ_ORDERS_FAIL"
export const DELETE_ORDER_HIST_REQUEST = "DELETE_ORDER_HIST_REQUEST"
export const DELETE_ORDER_HIST_SUCCESS = "DELETE_ORDER_HIST_SUCCESS"
export const DELETE_ORDER_HIST_FAIL = "DELETE_ORDER_HIST_FAIL"

export const CREATE_TEMP_CART = "CREATE_TEMP_CART"
export const ADD_TEMP_ITEM = "ADD_TEMP_ITEM"
export const EDIT_TEMP_ITEM = "EDIT_TEMP_ITEM"
export const DELETE_TEMP_ITEM = "DELETE_TEMP_ITEM"
export const EMPTY_CART = "EMPTY_CART"