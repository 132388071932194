export const CREATE_PART_REQUEST = "CREATE_PART_REQUEST"
export const CREATE_PART_SUCCESS = "CREATE_PART_SUCCESS"
export const CREATE_PART_FAIL = "CREATE_PART_FAIL"
export const UPDATE_PART_REQUEST = "UPDATE_PART_REQUEST"
export const UPDATE_PART_SUCCESS = "UPDATE_PART_SUCCESS"
export const UPDATE_PART_FAIL = "UPDATE_PART_FAIL"
export const GET_PARTS_REQUEST = "GET_PARTS_REQUEST"
export const GET_PARTS_SUCCESS = "GET_PARTS_SUCCESS"
export const GET_PARTS_FAIL = "GET_PARTS_FAIL"
export const DELETE_PART_REQUEST = "DELETE_PART_REQUEST"
export const DELETE_PART_SUCCESS = "DELETE_PART_SUCCESS"
export const DELETE_PART_FAIL = "DELETE_PART_FAIL"