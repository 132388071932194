export const CREATE_PRICE_REQUEST = "CREATE_PRICE_REQUEST"
export const CREATE_PRICE_SUCCESS = "CREATE_PRICE_SUCCESS"
export const CREATE_PRICE_FAILURE = "CREATE_PRICE_FAILURE"
export const READ_PRICE_REQUEST = "READ_PRICE_REQUEST"
export const READ_PRICE_SUCCESS = "READ_PRICE_SUCCESS"
export const READ_PRICE_FAILURE = "READ_PRICE_FAILURE"
export const UPDATE_PRICE_REQUEST = "UPDATE_PRICE_REQUEST"
export const UPDATE_PRICE_SUCCESS = "UPDATE_PRICE_SUCCESS"
export const UPDATE_PRICE_FAILURE = "UPDATE_PRICE_FAILURE"
export const DELETE_PRICE_REQUEST = "DELETE_PRICE_REQUEST"
export const DELETE_PRICE_SUCCESS = "DELETE_PRICE_SUCCESS"
export const DELETE_PRICE_FAILURE = "DELETE_PRICE_FAILURE"