export const CREATE_STAFF_REQ_REQUEST = "CREATE_STAFF_REQ_REQUEST"
export const CREATE_STAFF_REQ_SUCCESS = "CREATE_STAFF_REQ_SUCCESS"
export const CREATE_STAFF_REQ_FAIL = "CREATE_STAFF_REQ_FAIL"
export const READ_STAFF_REQ_REQUEST = "READ_STAFF_REQ_REQUEST"
export const READ_STAFF_REQ_SUCCESS = "READ_STAFF_REQ_SUCCESS"
export const READ_STAFF_REQ_FAIL = "READ_STAFF_REQ_FAIL"
export const UPDATE_STAFF_REQ_REQUEST = "UPDATE_STAFF_REQ_REQUEST"
export const UPDATE_STAFF_REQ_SUCCESS = "UPDATE_STAFF_REQ_SUCCESS"
export const UPDATE_STAFF_REQ_FAIL = "UPDATE_STAFF_REQ_FAIL"
export const CREATE_APPLY_REQUEST = "CREATE_APPLY_REQUEST"
export const CREATE_APPLY_SUCCESS = "CREATE_APPLY_SUCCESS"
export const CREATE_APPLY_FAIL = "CREATE_APPLY_FAIL"
export const READ_APPLY_REQUEST = "READ_APPLY_REQUEST"
export const READ_APPLY_SUCCESS = "READ_APPLY_SUCCESS"
export const READ_APPLY_FAIL = "READ_APPLY_FAIL"
export const UPDATE_APPLY_REQUEST = "UPDATE_APPLY_REQUEST"
export const UPDATE_APPLY_SUCCESS = "UPDATE_APPLY_SUCCESS"
export const UPDATE_APPLY_FAIL = "UPDATE_APPLY_FAIL"
export const DELETE_APPLY_REQUEST = "DELETE_APPLY_REQUEST"
export const DELETE_APPLY_SUCCESS = "DELETE_APPLY_SUCCESS"
export const DELETE_APPLY_FAIL = "DELETE_APPLY_FAIL"

export const CREATE_APP_NOTE_REQUEST = "CREATE_APP_NOTE_REQUEST"
export const CREATE_APP_NOTE_SUCCESS = "CREATE_APP_NOTE_SUCCESS"
export const CREATE_APP_NOTE_FAIL = "CREATE_APP_NOTE_FAIL"
export const READ_APP_NOTE_REQUEST = "READ_APP_NOTE_REQUEST"
export const READ_APP_NOTE_SUCCESS = "READ_APP_NOTE_SUCCESS"
export const READ_APP_NOTE_FAIL = "READ_APP_NOTE_FAIL"

export const CREATE_CAMPAIGN_REQUEST = "CREATE_CAMPAIGN_REQUEST"
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS"
export const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL"
export const READ_CAMPAIGN_REQUEST = "READ_CAMPAIGN_REQUEST"
export const READ_CAMPAIGN_SUCCESS = "READ_CAMPAIGN_SUCCESS"
export const READ_CAMPAIGN_FAIL = "READ_CAMPAIGN_FAIL"
export const UPDATE_CAMPAIGN_REQUEST = "UPDATE_CAMPAIGN_REQUEST"
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS"
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL"

export const CREATE_APPLICANT_MSG_REQUEST = "CREATE_APPLICANT_MSG_REQUEST"
export const CREATE_APPLICANT_MSG_SUCCESS = "CREATE_APPLICANT_MSG_SUCCESS"
export const CREATE_APPLICANT_MSG_FAIL = "CREATE_APPLICANT_MSG_FAIL"
export const READ_APPLICANT_MSG_REQUEST = "READ_APPLICANT_MSG_REQUEST"
export const READ_APPLICANT_MSG_SUCCESS = "READ_APPLICANT_MSG_SUCCESS"
export const READ_APPLICANT_MSG_FAIL = "READ_APPLICANT_MSG_FAIL"