export const load = {
    start: {
        loading: true,
        loaded: false,
        loadError: "",
    },
    success: {
        loading: false,
        loaded: true,
        loadError: "",
    },
    error : (message) => {
        return {
            loading: false,
            loaded: false,
            loadError: message,
        }
    },
    idle : {
        loading: false,
        loaded: false,
        loadError: "",
    }
}

export const create = {
    start: {
        creating: true,
        created: false,
        createError: "",
    },
    success: {
        creating: false,
        created: true,
        createError: "",
    },
    error : (message) => {
        return {
            creating: false,
            created: false,
            createError: message,
        }
    },
    idle : {
        creating: false,
        created: false,
        createError: "",
    }
}

export const update = {
    start: {
        updating: true,
        updated: false,
        updateError: "",
    },
    success: {
        updating: false,
        updated: true,
        updateError: "",
    },
    error : (message) => {
        return {
            updating: false,
            updated: false,
            updateError: message,
        }
    },
    idle : {
        updating : false,
        updated : false,
        updateError : ""
    }
}

export const _delete = {
    start: {
        deleting: true,
        deleted: false,
        deleteError: "",
    },
    success: {
        deleting: false,
        deleted: true,
        deleteError: "",
    },
    error : (message) => {
        return {
            deleting: false,
            deleted: false,
            deleteError: message,
        }
    },
    idle : {
        deleting: false,
        deleted: false,
        deleteError: ""
    }
} 

export const defaultStart = (_spread_data, _spread_object) => {
    return {
        ..._spread_data,
        ..._spread_object
    }
}

export const defaultSuccess = (_spread_data, _spread_object, name, data) => {
    return {
        ..._spread_data,
        ..._spread_object,
        [name] : data
    }
}

export const defaultFail = (_spread_data, _spread_object) => {
    return {
        ..._spread_data,
        ..._spread_object
    }
}