import {
    CREATE_LEAVE_REQUEST,
    CREATE_LEAVE_SUCCESS,
    CREATE_LEAVE_FAIL,
    READ_LEAVE_REQUEST,
    READ_LEAVE_SUCCESS,
    READ_LEAVE_FAIL,
    UPDATE_LEAVE_REQUEST,
    UPDATE_LEAVE_SUCCESS,
    UPDATE_LEAVE_FAIL,
    CREATE_HOLIDAY_REQUEST,
    CREATE_HOLIDAY_SUCCESS,
    CREATE_HOLIDAY_FAILURE,
    READ_HOLIDAY_REQUEST,
    READ_HOLIDAY_SUCCESS,
    READ_HOLIDAY_FAILURE,
    UPDATE_HOLIDAY_REQUEST,
    UPDATE_HOLIDAY_SUCCESS,
    UPDATE_HOLIDAY_FAILURE,
    DELETE_HOLIDAY_REQUEST,
    DELETE_HOLIDAY_SUCCESS,
    DELETE_HOLIDAY_FAILURE
} from "./constants"

import { load, create, update, defaultFail, defaultStart, defaultSuccess, _delete } from "../AsyncStatuses"
import { compareInt, isIterable } from "../../helpers"

const Leave = {
    leave_requests: {
        requests: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
    },
    holiday: {
        holidays: [],
        states: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    }
}

const LeavesReducer = (state = Leave, action) => {
    let newState = Object.assign({}, state)
    let newRequests = isIterable(newState?.leave_requests?.requests) ? [...newState?.leave_requests?.requests] : []
    switch (action.type) {
        case CREATE_LEAVE_REQUEST:
            return {
                ...newState,
                leave_requests: defaultStart(newState.leave_requests, create.start)
            }
        case CREATE_LEAVE_SUCCESS:
            return {
                ...newState,
                leave_requests: defaultSuccess(newState.leave_requests, create.success, "requests", [...newRequests, action.payload.request])
            }
        case CREATE_LEAVE_FAIL:
            return {
                ...newState,
                leave_requests: defaultFail(newState.leave_requests, create.error(action.payload?.message))
            }
        case READ_LEAVE_REQUEST:
            return {
                ...newState,
                leave_requests: defaultStart(newState.leave_requests, load.start)
            }
        case READ_LEAVE_SUCCESS:
            return {
                ...newState,
                leave_requests: defaultSuccess(newState.leave_requests, load.success, "requests", action.payload?.requests)
            }
        case READ_LEAVE_FAIL:
            return {
                ...newState,
                leave_requests: defaultFail(newState.leave_requests, load.error(action.payload?.message))
            }
        case UPDATE_LEAVE_REQUEST:
            return {
                ...newState,
                leave_requests: defaultStart(newState.leave_requests, update.start)
            }
        case UPDATE_LEAVE_SUCCESS:
            return {
                ...newState,
                leave_requests: defaultSuccess(newState.leave_requests, update.success, "requests", [...newRequests.filter(request => compareInt(request._id, action.payload?.request?._id) === false), action.payload?.request])
            }
        case UPDATE_LEAVE_FAIL:
            return {
                ...newState,
                leave_requests: defaultFail(newState.leave_requests, update.error(action.payload?.message))
            }

        case CREATE_HOLIDAY_REQUEST:
            return {
                ...newState,
                holiday: defaultStart(newState.holiday, create.start)
            }
        case CREATE_HOLIDAY_SUCCESS:
            const states = {}
            for (const s of action.payload.holiday_states) {
                if (states[s._holiday_id] === undefined) states[s._holiday_id] = []
                states[s._holiday_id].push(s._state_id)
            }

            return {
                ...newState,
                holiday: defaultSuccess(defaultSuccess(newState.holiday, create.success, "holidays", action.payload.holidays), create.success, "states", states)
            }
        case CREATE_HOLIDAY_FAILURE:
            return {
                ...newState,
                holiday: defaultFail(newState.holiday, create.error(action.payload?.message))
            }
        case READ_HOLIDAY_REQUEST:
            return {
                ...newState,
                holiday: defaultStart(newState.holiday, load.start)
            }
        case READ_HOLIDAY_SUCCESS:
            const _states = {}
            for (const s of action.payload.holiday_states) {
                if (_states[s._holiday_id] === undefined) _states[s._holiday_id] = []
                _states[s._holiday_id].push(s._state_id)
            }

            return {
                ...newState,
                holiday: defaultSuccess(defaultSuccess(newState.holiday, load.success, "holidays", action.payload.holidays), load.success, "states", _states)
            }
        case READ_HOLIDAY_FAILURE:
            return {
                ...newState,
                holiday: defaultFail(newState.holiday, load.error(action.payload?.message))
            }
        case UPDATE_HOLIDAY_REQUEST:
            return {
                ...newState,
                holiday: defaultStart(newState.holiday, update.start)
            }
        case UPDATE_HOLIDAY_SUCCESS:
            const __states = {}
            for (const s of action.payload.holiday_states) {
                if (__states[s._holiday_id] === undefined) __states[s._holiday_id] = []
                __states[s._holiday_id].push(s._state_id)
            }

            return {
                ...newState,
                holiday: defaultSuccess(defaultSuccess(newState.holiday, update.success, "holidays", action.payload.holidays), update.success, "states", __states)
            }
        case UPDATE_HOLIDAY_FAILURE:
            return {
                ...newState,
                holiday: defaultFail(newState.holiday, update.error(action.payload?.message))
            }
        case DELETE_HOLIDAY_REQUEST:
            return {
                ...newState,
                holiday: defaultStart(newState.holiday, _delete.start)
            }
        case DELETE_HOLIDAY_SUCCESS:
            return {
                ...newState,
                holiday: defaultSuccess(newState.holiday, _delete.success)
            }
        case DELETE_HOLIDAY_FAILURE:
            return {
                ...newState,
                holiday: defaultFail(newState.holiday, _delete.error(action.payload?.message))
            }

        default:
            return { ...state }
    }
}

export default LeavesReducer