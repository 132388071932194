import {
    SET_TABLE_FILTER,
    SET_VIEW_FILTER,
    SET_PAGE_FILTER,
    GENERATE_FILTERS,
    GENERATE_REACT_TABLE_FILTERS,
    SET_REACT_TABLE_FILTERS
} from "./constants.js"

const filters = {
    "/jobs/": {
        TableFilters: {
            "Status": "",
            "Order#": "",
            "License": "",
            "Technician": "",
            "Make": "",
            "Model": "",
            "Part": "",
            "Shop": "",
            "Order Time": "",
            "Start Time": "",
            "Finish Time": "",
            "Repair Time": "",
            "ID": "",
        },
        ViewFilters: {
            showRelevant: true,
            showPersonal: true,
            showOrders: true
        },
        PageFilters: {
            orders: 1,
            others: 1,
            default: 1
        }
    },
    newUpdate: true,
    updated: false
}


const TablesReducer = (state = filters, action) => {

    const newState = { ...state }

    switch (action.type) {
        case SET_TABLE_FILTER:
            return {
                ...newState,
                [action.payload.route]: {
                    ...newState[action.payload.route],
                    TableFilters: action.payload.filters,
                }
            }
        case SET_VIEW_FILTER:
            const cleared_filters = {}
            for (const f in newState[action.payload.route].TableFilters) {
                cleared_filters[f] = ""
            }

            return {
                ...newState,
                [action.payload.route]: {
                    ...newState[action.payload.route],
                    ViewFilters: {
                        ...newState[action.payload.route].ViewFilters,
                        [action.payload.filter.name]: action.payload.filter.value
                    },
                    PageFilters: {
                        default: 1
                    },
                    TableFilters: cleared_filters
                }
            }
        case SET_PAGE_FILTER:
            return {
                ...newState,
                [action.payload.route]: {
                    ...newState[action.payload.route],
                    PageFilters: {
                        ...newState[action.payload.route].PageFilters,
                        [action.payload.filter.name]: action.payload.filter.value
                    }
                }
            }
        case GENERATE_FILTERS:
            return {
                ...newState,
                [action.payload.route]: {
                    ...newState[action.payload.route],
                    TableFilters: action.payload.TableFilters,
                    PageFilters: { default: 1 },
                    shoudlAscend: action.payload.asc,
                    index: action.payload.index,
                    props: action.payload.props
                },
                updated: true
            }
        case "SET_INDEX_FILTER":
            return {
                ...newState,
                [action.payload.route]: {
                    ...newState[action.payload.route],
                    index: action.payload.index
                }
            }
        case "SET_ASCEND_FILTER":
            return {
                ...newState,
                [action.payload.route]: {
                    ...newState[action.payload.route],
                    shoudlAscend: action.payload.ascend
                }
            }
        case "CLEAR_ALL_FILTERS":
            return filters

        case GENERATE_REACT_TABLE_FILTERS:
            return {
                ...state,
                ["rt-" + action.payload]: {
                    filters: [],
                    page: 1,
                    perPage: 10
                }
            }
        case SET_REACT_TABLE_FILTERS:
            return {
                ...state,
                ["rt-" + action.payload.path]: {
                    filters: action.payload.filters,
                    page: action.payload.page,
                    perPage: action.payload.perPage
                }
            }
        default:
            return { ...state }
    }
}


export default TablesReducer