export const CREATE_CLOCKING_REQUEST = "CREATE_CLOCKING_REQUEST"
export const CREATE_CLOCKING_SUCCESS = "CREATE_CLOCKING_SUCCESS"
export const CREATE_CLOCKING_FAIL = "CREATE_CLOCKING_FAIL"

export const GET_CLOCKING_REQUEST = "GET_CLOCKING_REQUEST"
export const GET_CLOCKING_SUCCESS = "GET_CLOCKING_SUCCESS"
export const GET_CLOCKING_FAIL = "GET_CLOCKING_FAIL"

export const UPDATE_CLOCKING_REQUEST = "UPDATE_CLOCKING_REQUEST"
export const UPDATE_CLOCKING_SUCCESS = "UPDATE_CLOCKING_SUCCESS"
export const UPDATE_CLOCKING_FAIL = "UPDATE_CLOCKING_FAIL"

export const DELETE_CLOCKING_REQUEST = "DELETE_CLOCKING_REQUEST"
export const DELETE_CLOCKING_SUCCESS = "DELETE_CLOCKING_SUCCESS"
export const DELETE_CLOCKING_FAIL = "DELETE_CLOCKING_FAIL"



export const CREATE_T_PAUSE_REQUEST = "CREATE_T_PAUSE_REQUEST"
export const CREATE_T_PAUSE_SUCCESS = "CREATE_T_PAUSE_SUCCESS"
export const CREATE_T_PAUSE_FAIL = "CREATE_T_PAUSE_FAIL"

export const GET_PAUSE_REQUEST = "GET_PAUSE_REQUEST"
export const GET_PAUSE_SUCCESS = "GET_PAUSE_SUCCESS"
export const GET_PAUSE_FAIL = "GET_PAUSE_FAIL"

export const UPDATE_T_PAUSE_REQUEST = "UPDATE_T_PAUSE_REQUEST"
export const UPDATE_T_PAUSE_SUCCESS = "UPDATE_T_PAUSE_SUCCESS"
export const UPDATE_T_PAUSE_FAIL = "UPDATE_T_PAUSE_FAIL"

export const DELETE_PAUSE_REQUEST = "DELETE_PAUSE_REQUEST"
export const DELETE_PAUSE_SUCCESS = "DELETE_PAUSE_SUCCESS"
export const DELETE_PAUSE_FAIL = "DELETE_PAUSE_FAIL"