export const CREATE_TRAVEL_REQUEST = "CREATE_TRAVEL_REQUEST"
export const CREATE_TRAVEL_SUCCESS = "CREATE_TRAVEL_SUCCESS"
export const CREATE_TRAVEL_FAIL = "CREATE_TRAVEL_FAIL"

export const GET_TRAVEL_REQUEST = "GET_TRAVEL_REQUEST"
export const GET_TRAVEL_SUCCESS = "GET_TRAVEL_SUCCESS"
export const GET_TRAVEL_FAIL = "GET_TRAVEL_FAIL"

export const UPDATE_TRAVEL_REQUEST = "UPDATE_TRAVEL_REQUEST"
export const UPDATE_TRAVEL_SUCCESS = "UPDATE_TRAVEL_SUCCESS"
export const UPDATE_TRAVEL_FAIL = "UPDATE_TRAVEL_FAIL"

export const DELETE_TRAVEL_REQUEST = "DELETE_TRAVEL_REQUEST"
export const DELETE_TRAVEL_SUCCESS = "DELETE_TRAVEL_SUCCESS"
export const DELETE_TRAVEL_FAIL = "DELETE_TRAVEL_FAIL"
