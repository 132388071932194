export const GET_GENERAL_REQUEST = "GET_GENERAL_REQUEST"
export const GET_GENERAL_SUCCESS = "GET_GENERAL_SUCCESS"
export const GET_GENERAL_FAIL = "GET_GENERAL_FAIL"
export const READ_STATES_REQUEST = "READ_STATES_REQUEST"
export const READ_STATES_SUCCESS = "READ_STATES_SUCCESS"
export const READ_STATES_FAIL = "READ_STATES_FAIL"
export const CREATE_STATES_REQUEST = "CREATE_STATES_REQUEST"
export const CREATE_STATES_SUCCESS = "CREATE_STATES_SUCCESS"
export const CREATE_STATES_FAIL = "CREATE_STATES_FAIL"
export const UPDATE_STATES_REQUEST = "UPDATE_STATES_REQUEST"
export const UPDATE_STATES_SUCCESS = "UPDATE_STATES_SUCCESS"
export const UPDATE_STATES_FAIL = "UPDATE_STATES_FAIL"
export const DELETE_STATES_REQUEST = "DELETE_STATES_REQUEST"
export const DELETE_STATES_SUCCESS = "DELETE_STATES_SUCCESS"
export const DELETE_STATES_FAIL = "DELETE_STATES_FAIL"