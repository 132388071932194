import {
    CREATE_PART_REQUEST,
    CREATE_PART_SUCCESS,
    CREATE_PART_FAIL,
    UPDATE_PART_REQUEST,
    UPDATE_PART_SUCCESS,
    UPDATE_PART_FAIL,
    GET_PARTS_REQUEST,
    GET_PARTS_SUCCESS,
    GET_PARTS_FAIL,
    DELETE_PART_REQUEST,
    DELETE_PART_SUCCESS,
    DELETE_PART_FAIL
} from "./constants"

const stocks = {
    parts: {

        allParts: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: "",

        deleting: false,
        deleted: false,
        deleteError: ""

    }
}

const StocksReducer = (state = stocks, action) => {
    let newState = Object.assign({}, state)
    switch (action.type) {
        case CREATE_PART_REQUEST:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_PART_SUCCESS:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    creating: false,
                    created: true,
                    createError: "",
                    allParts: [
                        ...newState.parts.allParts,
                        action.payload._part
                    ]
                }
            }
            return newState
        case CREATE_PART_FAIL:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case GET_PARTS_REQUEST:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case GET_PARTS_SUCCESS:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    allParts: action.payload._stock_parts
                }
            }
            return newState
        case GET_PARTS_FAIL:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case UPDATE_PART_REQUEST:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    updating: true,
                    updated: false,
                    updateError: "",
                }
            }
            return newState
        case UPDATE_PART_SUCCESS:
            let updatedParts = newState.parts.allParts.filter(part => parseInt(part._id) !== action.payload._part._id)
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    updating: false,
                    updated: true,
                    updateError: "",
                    allParts: [
                        ...updatedParts,
                        action.payload._part
                    ]
                }
            }
            return newState
        case UPDATE_PART_FAIL:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    updating: false,
                    updated: false,
                    updateError: action.payload.message,
                }
            }
            return newState
        case DELETE_PART_REQUEST:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    deleting: true,
                    deleted: false,
                    deleteError: "",
                }
            }
            return newState
        case DELETE_PART_SUCCESS:
            let deletedParts = newState.parts.allParts.filter(part => parseInt(part._id) !== action.payload._deleted_id)
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    deleting: false,
                    deleted: true,
                    deleteError: "",
                    allParts: deletedParts
                }
            }
            return newState
        case DELETE_PART_FAIL:
            newState = {
                ...newState,
                parts: {
                    ...newState.parts,
                    deleting: false,
                    deleted: false,
                    deleteError: action.payload.message,
                }
            }
            return newState
        default:
            return { ...state };
    }
}

export default StocksReducer