import {
    CREATE_CLOCKING_REQUEST,
    CREATE_CLOCKING_SUCCESS,
    CREATE_CLOCKING_FAIL,
    GET_CLOCKING_REQUEST,
    GET_CLOCKING_SUCCESS,
    GET_CLOCKING_FAIL,
    UPDATE_CLOCKING_REQUEST,
    UPDATE_CLOCKING_SUCCESS,
    UPDATE_CLOCKING_FAIL,
    DELETE_CLOCKING_REQUEST,
    DELETE_CLOCKING_SUCCESS,
    DELETE_CLOCKING_FAIL,
    CREATE_T_PAUSE_REQUEST,
    CREATE_T_PAUSE_SUCCESS,
    CREATE_T_PAUSE_FAIL,
    UPDATE_T_PAUSE_REQUEST,
    UPDATE_T_PAUSE_SUCCESS,
    UPDATE_T_PAUSE_FAIL,
    GET_PAUSE_REQUEST,
    GET_PAUSE_SUCCESS,
    GET_PAUSE_FAIL,
    DELETE_PAUSE_REQUEST,
    DELETE_PAUSE_SUCCESS,
    DELETE_PAUSE_FAIL
} from "./constants"

const TimeTracking = {
    _clocking: {
        _clockings: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: "",

        deleting: false,
        deleted: false,
        deleteError: ""
    },

    _pause: {
        _pauses: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: "",

        deleting: false,
        deleted: false,
        deleteError: ""
    }
}

const TrackingReducer = (state = TimeTracking, action) => {
    let newState = Object.assign({}, state)
    let newClockings = []
    let newPauses = []
    switch (action.type) {
        case CREATE_CLOCKING_REQUEST:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    created: false,
                    creating: true,
                    createError: ""
                }
            }
            return newState
        case CREATE_CLOCKING_SUCCESS:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    created: true,
                    creating: false,
                    createError: "",
                    _clockings: newState._clocking._clockings === undefined ? [action.payload._clocking] : [...newState._clocking._clockings, action.payload._clocking]
                }
            }
            return newState
        case CREATE_CLOCKING_FAIL:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    created: false,
                    creating: false,
                    createError: action.payload.message
                }
            }
            return newState
        case GET_CLOCKING_REQUEST:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    loaded: false,
                    loading: true,
                    loadError: ""
                }
            }
            return newState
        case GET_CLOCKING_SUCCESS:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    loaded: true,
                    loading: false,
                    loadError: "",
                    _clockings: action.payload._clockings
                }
            }
            return newState
        case GET_CLOCKING_FAIL:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    loaded: false,
                    loading: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case UPDATE_CLOCKING_REQUEST:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    updated: false,
                    updating: true,
                    updateError: ""
                }
            }
            return newState
        case UPDATE_CLOCKING_SUCCESS:
            newClockings = newState._clocking._clockings.filter(_c => parseInt(_c._id) !== parseInt(action.payload._clocking._id))
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    updated: true,
                    updating: false,
                    updateError: "",
                    _clockings: [...newClockings, action.payload._clocking]
                }
            }
            return newState
        case UPDATE_CLOCKING_FAIL:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    updated: false,
                    updating: false,
                    updateError: action.payload.message,
                }
            }
            return newState
        case DELETE_CLOCKING_REQUEST:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    deleting: true,
                    deleted: false,
                    updateError: "",
                }
            }
            return newState
        case DELETE_CLOCKING_SUCCESS:
            newClockings = newState._clocking._clockings.filter(c => parseInt(c._id) !== parseInt(action.payload._id))
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    deleting: false,
                    deleted: true,
                    updateError: "",
                    _clockings: newClockings
                }
            }
            return newState
        case DELETE_CLOCKING_FAIL:
            newState = {
                ...newState,
                _clocking: {
                    ...newState._clocking,
                    deleting: false,
                    deleted: false,
                    updateError: action.payload.message,
                }
            }
            return newState
        case CREATE_T_PAUSE_REQUEST:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    created: false,
                    creating: true,
                    createError: ""
                }
            }
            return newState
        case CREATE_T_PAUSE_SUCCESS:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    created: true,
                    creating: false,
                    createError: "",
                    _pauses: newState._pause._pauses === undefined ? [action.payload._pause] : [...newState._pause._pauses, action.payload._pause]
                }
            }
            return newState
        case CREATE_T_PAUSE_FAIL:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    created: false,
                    creating: false,
                    createError: action.payload.message
                }
            }
            return newState
        case GET_PAUSE_REQUEST:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    loaded: false,
                    loading: true,
                    loadError: ""
                }
            }
            return newState
        case GET_PAUSE_SUCCESS:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    loaded: true,
                    loading: false,
                    loadError: "",
                    _pauses: action.payload._pauses
                }
            }
            return newState
        case GET_PAUSE_FAIL:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    loaded: false,
                    loading: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case UPDATE_T_PAUSE_REQUEST:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    updated: false,
                    updating: true,
                    updateError: ""
                }
            }
            return newState
        case UPDATE_T_PAUSE_SUCCESS:
            newPauses = newState._pause._pauses.filter(_c => parseInt(_c._id) !== parseInt(action.payload._pause._id))
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    updated: true,
                    updating: false,
                    updateError: "",
                    _pauses: [...newPauses, action.payload._pause]
                }
            }
            return newState
        case UPDATE_T_PAUSE_FAIL:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    updated: false,
                    updating: false,
                    updateError: action.payload.message,
                }
            }
            return newState
        case DELETE_PAUSE_REQUEST:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    deleting: true,
                    deleted: false,
                    updateError: "",
                }
            }
            return newState
        case DELETE_PAUSE_SUCCESS:
            newPauses = newState._pause._pauses.filter(c => parseInt(c._id) !== parseInt(action.payload._id))
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    deleting: false,
                    deleted: true,
                    updateError: "",
                    _pauses: newPauses
                }
            }
            return newState
        case DELETE_PAUSE_FAIL:
            newState = {
                ...newState,
                _pause: {
                    ...newState._pause,
                    deleting: false,
                    deleted: false,
                    updateError: action.payload.message,
                }
            }
            return newState

        default:
            return { ...state };
    }
}

export default TrackingReducer