import {
    READ_PART_TYPE_REQUEST,
    READ_PART_TYPE_SUCCESS,
    READ_PART_TYPE_FAILURE,
    CREATE_PART_TYPE_REQUEST,
    CREATE_PART_TYPE_SUCCESS,
    CREATE_PART_TYPE_FAILURE,
    UPDATE_PART_TYPE_REQUEST,
    UPDATE_PART_TYPE_SUCCESS,
    UPDATE_PART_TYPE_FAILURE,
    DELETE_PART_TYPE_REQUEST,
    DELETE_PART_TYPE_SUCCESS,
    DELETE_PART_TYPE_FAILURE,
    CREATE_MAKE_REQUEST,
    CREATE_MAKE_SUCCESS,
    CREATE_MAKE_FAILURE,
    READ_MAKE_REQUEST,
    READ_MAKE_SUCCESS,
    READ_MAKE_FAILURE,
    UPDATE_MAKE_REQUEST,
    UPDATE_MAKE_SUCCESS,
    UPDATE_MAKE_FAILURE,
    DELETE_MAKE_REQUEST,
    DELETE_MAKE_SUCCESS,
    DELETE_MAKE_FAILURE,
    CREATE_MODEL_REQUEST,
    CREATE_MODEL_SUCCESS,
    CREATE_MODEL_FAILURE,
    READ_MODEL_REQUEST,
    READ_MODEL_SUCCESS,
    READ_MODEL_FAILURE,
    UPDATE_MODEL_REQUEST,
    UPDATE_MODEL_SUCCESS,
    UPDATE_MODEL_FAILURE,
    DELETE_MODEL_REQUEST,
    DELETE_MODEL_SUCCESS,
    DELETE_MODEL_FAILURE,
    CREATE_BODY_TYPE_REQUEST,
    CREATE_BODY_TYPE_SUCCESS,
    CREATE_BODY_TYPE_FAILURE,
    READ_BODY_TYPE_REQUEST,
    READ_BODY_TYPE_SUCCESS,
    READ_BODY_TYPE_FAILURE,
    UPDATE_BODY_TYPE_REQUEST,
    UPDATE_BODY_TYPE_SUCCESS,
    UPDATE_BODY_TYPE_FAILURE,
    DELETE_BODY_TYPE_REQUEST,
    DELETE_BODY_TYPE_SUCCESS,
    DELETE_BODY_TYPE_FAILURE,
    CREATE_ISO_REQUEST,
    CREATE_ISO_SUCCESS,
    CREATE_ISO_FAILURE,
    READ_ISO_REQUEST,
    READ_ISO_SUCCESS,
    READ_ISO_FAILURE,
    UPDATE_ISO_REQUEST,
    UPDATE_ISO_SUCCESS,
    UPDATE_ISO_FAILURE,
    CREATE_ISO_TYPE_REQUEST,
    CREATE_ISO_TYPE_SUCCESS,
    CREATE_ISO_TYPE_FAILURE,
    READ_ISO_TYPE_REQUEST,
    READ_ISO_TYPE_SUCCESS,
    READ_ISO_TYPE_FAILURE,
    UPDATE_ISO_TYPE_REQUEST,
    UPDATE_ISO_TYPE_SUCCESS,
    UPDATE_ISO_TYPE_FAILURE,
} from "./constants"

const settings = {
    part: {
        parts: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
        deleteStatus: "",
        deleteMessage: "",
    },
    make: {
        makes: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
        deleteStatus: "",
        deleteMessage: "",
    },
    model: {
        models: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
        deleteStatus: "",
        deleteMessage: "",
    },
    bodyType: {
        bodyTypes: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
        deleteStatus: "",
        deleteMessage: "",
    },
    iso: {
        isos: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
    },
    isoTypes: {
        isoTypes: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
    }
}

const SettingsReducer = (state = settings, action) => {
    let work = []
    switch (action.type) {
        case READ_PART_TYPE_REQUEST:
            return {
                ...state,
                part: {
                    ...state.part,
                    readStatus: "pending",
                    readMessage: "Loading parts... Please wait",
                }
            }
        case READ_PART_TYPE_SUCCESS:
            work = []
            for (const part of action.payload.parts) work[part._id] = part
            return {
                ...state,
                part: {
                    ...state.part,
                    readStatus: "success",
                    readMessage: "parts loaded.",
                    parts: work
                }
            }
        case READ_PART_TYPE_FAILURE:
            return {
                ...state,
                part: {
                    ...state.part,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_PART_TYPE_REQUEST:
            return {
                ...state,
                part: {
                    ...state.part,
                    createStatus: "pending",
                    createMessage: "Creating part... Please wait",
                }
            }
        case CREATE_PART_TYPE_SUCCESS:
            work = [...state.part.parts]
            work[action.payload.part._id] = action.payload.part
            return {
                ...state,
                part: {
                    ...state.part,
                    createStatus: "success",
                    createMessage: "part created.",
                    parts: work
                }
            }
        case CREATE_PART_TYPE_FAILURE:
            return {
                ...state,
                part: {
                    ...state.part,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_PART_TYPE_REQUEST:
            return {
                ...state,
                part: {
                    ...state.part,
                    updateStatus: "pending",
                    updateMessage: "Updating part... Please wait",
                }
            }
        case UPDATE_PART_TYPE_SUCCESS:
            work = [...state.part.parts]
            work[action.payload.part._id] = action.payload.part
            return {
                ...state,
                part: {
                    ...state.part,
                    updateStatus: "success",
                    updateMessage: "Part updated.",
                    parts: work
                }
            }
        case UPDATE_PART_TYPE_FAILURE:
            return {
                ...state,
                part: {
                    ...state.part,
                    updateStatus: "error",
                    updateMessage: action.payload?.message + "123"
                }
            }
        case DELETE_PART_TYPE_REQUEST:
            return {
                ...state,
                part: {
                    ...state.part,
                    deleteStatus: "pending",
                    deleteMessage: "Deleting part... Please wait.",
                }
            }
        case DELETE_PART_TYPE_SUCCESS:
            work = [...state.part.parts]
            work[action.payload.part._id] = action.payload.part
            return {
                ...state,
                part: {
                    ...state.part,
                    deleteStatus: "success",
                    deleteMessage: action.payload.part._archived === 1 ? "Part Deleted" : "Part Retrived",
                    parts: work
                }
            }
        case DELETE_PART_TYPE_FAILURE:
            return {
                ...state,
                part: {
                    ...state.part,
                    deleteStatus: "error",
                    deleteMessage: action.payload?.message
                }
            }
        case READ_MAKE_REQUEST:
            return {
                ...state,
                make: {
                    ...state.make,
                    readStatus: "pending",
                    readMessage: "Loading makes... Please wait",
                }
            }
        case READ_MAKE_SUCCESS:
            work = []
            for (const make of action.payload.makes) work[make._id] = make
            return {
                ...state,
                make: {
                    ...state.make,
                    readStatus: "success",
                    readMessage: "makes loaded.",
                    makes: work
                }
            }
        case READ_MAKE_FAILURE:
            return {
                ...state,
                make: {
                    ...state.make,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_MAKE_REQUEST:
            return {
                ...state,
                make: {
                    ...state.make,
                    createStatus: "pending",
                    createMessage: "Creating make... Please wait",
                }
            }
        case CREATE_MAKE_SUCCESS:
            work = [...state.make.makes]
            work[action.payload.make._id] = action.payload.make
            return {
                ...state,
                make: {
                    ...state.make,
                    createStatus: "success",
                    createMessage: "make created.",
                    makes: work
                }
            }
        case CREATE_MAKE_FAILURE:
            return {
                ...state,
                make: {
                    ...state.make,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_MAKE_REQUEST:
            return {
                ...state,
                make: {
                    ...state.make,
                    updateStatus: "pending",
                    updateMessage: "Updating make... Please wait",
                }
            }
        case UPDATE_MAKE_SUCCESS:
            work = [...state.make.makes]
            work[action.payload.make._id] = action.payload.make
            return {
                ...state,
                make: {
                    ...state.make,
                    updateStatus: "success",
                    updateMessage: "make updated.",
                    makes: work
                }
            }
        case UPDATE_MAKE_FAILURE:
            return {
                ...state,
                make: {
                    ...state.make,
                    updateStatus: "error",
                    updateMessage: action.payload?.message + "123"
                }
            }
        case DELETE_MAKE_REQUEST:
            return {
                ...state,
                make: {
                    ...state.make,
                    deleteStatus: "pending",
                    deleteMessage: "Deleting make... Please wait.",
                }
            }
        case DELETE_MAKE_SUCCESS:
            work = [...state.make.makes]
            work[action.payload.make._id] = action.payload.make
            return {
                ...state,
                make: {
                    ...state.make,
                    deleteStatus: "success",
                    deleteMessage: action.payload.make._archived === 1 ? "make Deleted" : "make Retrived",
                    makes: work
                }
            }
        case DELETE_MAKE_FAILURE:
            return {
                ...state,
                make: {
                    ...state.make,
                    deleteStatus: "error",
                    deleteMessage: action.payload?.message
                }
            }
        case READ_MODEL_REQUEST:
            return {
                ...state,
                model: {
                    ...state.model,
                    readStatus: "pending",
                    readMessage: "Loading models... Please wait",
                }
            }
        case READ_MODEL_SUCCESS:
            work = []
            for (const model of action.payload.models) work[model._id] = model
            return {
                ...state,
                model: {
                    ...state.model,
                    readStatus: "success",
                    readMessage: "models loaded.",
                    models: work
                }
            }
        case READ_MODEL_FAILURE:
            return {
                ...state,
                model: {
                    ...state.model,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_MODEL_REQUEST:
            return {
                ...state,
                model: {
                    ...state.model,
                    createStatus: "pending",
                    createMessage: "Creating model... Please wait",
                }
            }
        case CREATE_MODEL_SUCCESS:
            work = [...state.model.models]
            work[action.payload.model._id] = action.payload.model
            return {
                ...state,
                model: {
                    ...state.model,
                    createStatus: "success",
                    createMessage: "model created.",
                    models: work
                }
            }
        case CREATE_MODEL_FAILURE:
            return {
                ...state,
                model: {
                    ...state.model,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_MODEL_REQUEST:
            return {
                ...state,
                model: {
                    ...state.model,
                    updateStatus: "pending",
                    updateMessage: "Updating model... Please wait",
                }
            }
        case UPDATE_MODEL_SUCCESS:
            work = [...state.model.models]
            work[action.payload.model._id] = action.payload.model
            return {
                ...state,
                model: {
                    ...state.model,
                    updateStatus: "success",
                    updateMessage: "model updated.",
                    models: work
                }
            }
        case UPDATE_MODEL_FAILURE:
            return {
                ...state,
                model: {
                    ...state.model,
                    updateStatus: "error",
                    updateMessage: action.payload?.message + "123"
                }
            }
        case DELETE_MODEL_REQUEST:
            return {
                ...state,
                model: {
                    ...state.model,
                    deleteStatus: "pending",
                    deleteMessage: "Deleting model... Please wait.",
                }
            }
        case DELETE_MODEL_SUCCESS:
            work = [...state.model.models]
            work[action.payload.model._id] = action.payload.model
            return {
                ...state,
                model: {
                    ...state.model,
                    deleteStatus: "success",
                    deleteMessage: action.payload.model._archived === 1 ? "model Deleted" : "model Retrived",
                    models: work
                }
            }
        case DELETE_MODEL_FAILURE:
            return {
                ...state,
                model: {
                    ...state.model,
                    deleteStatus: "error",
                    deleteMessage: action.payload?.message
                }
            }
        case READ_BODY_TYPE_REQUEST:
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    readStatus: "pending",
                    readMessage: "Loading Body Types... Please wait",
                }
            }
        case READ_BODY_TYPE_SUCCESS:
            work = []
            for (const bodyType of action.payload.body_types) work[bodyType._id] = bodyType
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    readStatus: "success",
                    readMessage: "Body Types loaded.",
                    bodyTypes: work
                }
            }
        case READ_BODY_TYPE_FAILURE:
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_BODY_TYPE_REQUEST:
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    createStatus: "pending",
                    createMessage: "Creating Body Type... Please wait",
                }
            }
        case CREATE_BODY_TYPE_SUCCESS:
            work = [...state.bodyType.bodyTypes]
            work[action.payload.body_type._id] = action.payload.body_type
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    createStatus: "success",
                    createMessage: "Body Type created.",
                    bodyTypes: work
                }
            }
        case CREATE_BODY_TYPE_FAILURE:
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_BODY_TYPE_REQUEST:
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    updateStatus: "pending",
                    updateMessage: "Updating Body Type... Please wait",
                }
            }
        case UPDATE_BODY_TYPE_SUCCESS:
            work = [...state.bodyType.bodyTypes]
            work[action.payload.body_type._id] = action.payload.body_type
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    updateStatus: "success",
                    updateMessage: "Body Type updated.",
                    bodyTypes: work
                }
            }
        case UPDATE_BODY_TYPE_FAILURE:
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    updateStatus: "error",
                    updateMessage: action.payload?.message + "123"
                }
            }
        case DELETE_BODY_TYPE_REQUEST:
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    deleteStatus: "pending",
                    deleteMessage: "Deleting Body Type... Please wait.",
                }
            }
        case DELETE_BODY_TYPE_SUCCESS:
            work = [...state.bodyType.bodyTypes]
            work[action.payload.body_type._id] = action.payload.body_type
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    deleteStatus: "success",
                    deleteMessage: action.payload.body_type._archived === 1 ? "Body Type Deleted" : "Body Type Retrived",
                    bodyTypes: work
                }
            }
        case DELETE_BODY_TYPE_FAILURE:
            return {
                ...state,
                bodyType: {
                    ...state.bodyType,
                    deleteStatus: "error",
                    deleteMessage: action.payload?.message
                }
            }
        case READ_ISO_REQUEST:
            return {
                ...state,
                iso: {
                    ...state.iso,
                    readStatus: "pending",
                    readMessage: "Loading ISOs... Please wait",
                }
            }
        case READ_ISO_SUCCESS:
            work = []
            for (const iso of action.payload.isos) work[iso._id] = iso
            return {
                ...state,
                iso: {
                    ...state.iso,
                    readStatus: "success",
                    readMessage: "ISOs loaded.",
                    isos: work
                }
            }
        case READ_ISO_FAILURE:
            return {
                ...state,
                iso: {
                    ...state.iso,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_ISO_REQUEST:
            return {
                ...state,
                iso: {
                    ...state.iso,
                    createStatus: "pending",
                    createMessage: "Creating ISO... Please wait",
                }
            }
        case CREATE_ISO_SUCCESS:
            work = [...state.iso.isos]
            work[action.payload.iso._id] = action.payload.iso
            return {
                ...state,
                iso: {
                    ...state.iso,
                    createStatus: "success",
                    createMessage: "ISO created.",
                    isos: work
                }
            }
        case CREATE_ISO_FAILURE:
            return {
                ...state,
                iso: {
                    ...state.iso,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_ISO_REQUEST:
            return {
                ...state,
                iso: {
                    ...state.iso,
                    updateStatus: "pending",
                    updateMessage: "Updating ISO... Please wait",
                }
            }
        case UPDATE_ISO_SUCCESS:
            work = [...state.iso.isos]
            work[action.payload.iso._id] = action.payload.iso
            return {
                ...state,
                iso: {
                    ...state.iso,
                    updateStatus: "success",
                    updateMessage: "ISO updated.",
                    isos: work
                }
            }
        case UPDATE_ISO_FAILURE:
            return {
                ...state,
                iso: {
                    ...state.iso,
                    updateStatus: "error",
                    updateMessage: action.payload?.message
                }
            }
        case READ_ISO_TYPE_REQUEST:
            return {
                ...state,
                isoTypes: {
                    ...state.isoTypes,
                    readStatus: "pending",
                    readMessage: "Loading ISO Types... Please wait",
                }
            }
        case READ_ISO_TYPE_SUCCESS:
            work = []
            for (const iso of action.payload.iso_types) work[iso._id] = iso
            return {
                ...state,
                isoTypes: {
                    ...state.isoTypes,
                    readStatus: "success",
                    readMessage: "ISO Typess loaded.",
                    isoTypes: work
                }
            }
        case READ_ISO_TYPE_FAILURE:
            return {
                ...state,
                isoTypes: {
                    ...state.isoTypes,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_ISO_TYPE_REQUEST:
            return {
                ...state,
                isoTypes: {
                    ...state.isoTypes,
                    createStatus: "pending",
                    createMessage: "Creating ISO Type... Please wait",
                }
            }
        case CREATE_ISO_TYPE_SUCCESS:
            work = [...state.isoTypes.isos]
            work[action.payload.iso_type._id] = action.payload.iso_type
            return {
                ...state,
                isoTypes: {
                    ...state.isoTypes,
                    createStatus: "success",
                    createMessage: "ISO Type created.",
                    isoTypes: work
                }
            }
        case CREATE_ISO_TYPE_FAILURE:
            return {
                ...state,
                isoTypes: {
                    ...state.isoTypes,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_ISO_TYPE_REQUEST:
            return {
                ...state,
                isoTypes: {
                    ...state.isoTypes,
                    updateStatus: "pending",
                    updateMessage: "Updating ISO Type... Please wait",
                }
            }
        case UPDATE_ISO_TYPE_SUCCESS:
            work = [...state.isoTypes.isoTypes]
            work[action.payload.iso_type._id] = action.payload.iso_type
            return {
                ...state,
                isoTypes: {
                    ...state.isoTypes,
                    updateStatus: "success",
                    updateMessage: "ISO Type updated.",
                    isoTypes: work
                }
            }
        case UPDATE_ISO_TYPE_FAILURE:
            return {
                ...state,
                isoTypes: {
                    ...state.isoTypes,
                    updateStatus: "error",
                    updateMessage: action.payload?.message
                }
            }
        default:
            return { ...state };
    }
}

export default SettingsReducer