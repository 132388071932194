export const READ_SHOP_PRODUCTS_REQUEST = "READ_SHOP_PRODUCTS_REQUEST"
export const READ_SHOP_PRODUCTS_SUCCESS = "READ_SHOP_PRODUCTS_SUCCESS"
export const READ_SHOP_PRODUCTS_FAILURE = "READ_SHOP_PRODUCTS_FAILURE"
export const CREATE_SHOP_ORDERS_REQUEST = "CREATE_SHOP_ORDERS_REQUEST"
export const CREATE_SHOP_ORDERS_SUCCESS = "CREATE_SHOP_ORDERS_SUCCESS"
export const CREATE_SHOP_ORDERS_FAILURE = "CREATE_SHOP_ORDERS_FAILURE"
export const READ_SHOP_ORDERS_REQUEST = "READ_SHOP_ORDERS_REQUEST"
export const READ_SHOP_ORDERS_SUCCESS = "READ_SHOP_ORDERS_SUCCESS"
export const READ_SHOP_ORDERS_FAILURE = "READ_SHOP_ORDERS_FAILURE"
export const UPDATE_SHOP_ORDERS_REQUEST = "UPDATE_SHOP_ORDERS_REQUEST"
export const UPDATE_SHOP_ORDERS_SUCCESS = "UPDATE_SHOP_ORDERS_SUCCESS"
export const UPDATE_SHOP_ORDERS_FAILURE = "UPDATE_SHOP_ORDERS_FAILURE"
export const READ_SHOP_DATA_REQUEST = "READ_SHOP_DATA_REQUEST"
export const READ_SHOP_DATA_SUCCESS = "READ_SHOP_DATA_SUCCESS"
export const READ_SHOP_DATA_FAILURE = "READ_SHOP_DATA_FAILURE"
export const READ_TECH_PACKAGES_REQUEST = "READ_TECH_PACKAGES_REQUEST"
export const READ_TECH_PACKAGES_SUCCESS = "READ_TECH_PACKAGES_SUCCESS"
export const READ_TECH_PACKAGES_FAILURE = "READ_TECH_PACKAGES_FAILURE"
export const READ_ORDER_REQUEST = "READ_ORDER_REQUEST"
export const READ_ORDER_SUCCESS = "READ_ORDER_SUCCESS"
export const READ_ORDER_FAILURE = "READ_ORDER_FAILURE"
export const READ_PACKAGE_REQUEST = "READ_PACKAGE_REQUEST"
export const READ_PACKAGE_SUCCESS = "READ_PACKAGE_SUCCESS"
export const READ_PACKAGE_FAILURE = "READ_PACKAGE_FAILURE"