import {
    CREATE_DOC_REQUEST,
    CREATE_DOC_SUCCESS,
    CREATE_DOC_FAIL,
    READ_DOC_REQUEST,
    READ_DOC_SUCCESS,
    READ_DOC_FAIL,
    UPDATE_DOC_REQUEST,
    UPDATE_DOC_SUCCESS,
    UPDATE_DOC_FAIL,
    DELETE_DOC_REQUEST,
    DELETE_DOC_SUCCESS,
    DELETE_DOC_FAIL,
    CREATE_DOC_TITLE_REQUEST,
    CREATE_DOC_TITLE_SUCCESS,
    CREATE_DOC_TITLE_FAIL,
    READ_DOC_TITLE_REQUEST,
    READ_DOC_TITLE_SUCCESS,
    READ_DOC_TITLE_FAIL,
    UPDATE_DOC_TITLE_REQUEST,
    UPDATE_DOC_TITLE_SUCCESS,
    UPDATE_DOC_TITLE_FAIL,
    CREATE_FOLDER_REQUEST,
    CREATE_FOLDER_SUCCESS,
    CREATE_FOLDER_FAIL,
    READ_FOLDER_REQUEST,
    READ_FOLDER_SUCCESS,
    READ_FOLDER_FAIL,
    UPDATE_FOLDER_REQUEST,
    UPDATE_FOLDER_SUCCESS,
    UPDATE_FOLDER_FAIL,
    DELETE_FOLDER_REQUEST,
    DELETE_FOLDER_SUCCESS,
    DELETE_FOLDER_FAIL,
} from "./constants"

import { load, create, update, _delete, defaultFail, defaultStart, defaultSuccess } from "../AsyncStatuses"
import { isIterable } from "../../helpers"

const Documents = {
    title: {
        titles: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
    },
    document: {
        documents: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    },
    folder: {
        folders: [],
        items: [],
        access: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
        ..._delete.idle
    }
}

const LeavesReducer = (state = Documents, action) => {
    let newState = Object.assign({}, state)
    let newDocuments = isIterable(newState?.document?.documents) ? [...newState?.document?.documents] : []
    let _folders = []
    let _items = []
    let _access = []
    switch (action.type) {
        case CREATE_DOC_REQUEST:
            return {
                ...newState,
                document: defaultStart(newState.document, create.start)
            }
        case CREATE_DOC_SUCCESS:
            return {
                ...newState,
                document: defaultSuccess(newState.document, create.success, "documents", [...newDocuments, action.payload.document])
            }
        case CREATE_DOC_FAIL:
            return {
                ...newState,
                document: defaultFail(newState.document, create.error(action.payload?.message))
            }
        case READ_DOC_REQUEST:
            return {
                ...newState,
                document: defaultStart(newState.document, load.start)
            }
        case READ_DOC_SUCCESS:
            return {
                ...newState,
                document: defaultSuccess(newState.document, load.success, "documents", action.payload.documents)
            }
        case READ_DOC_FAIL:
            return {
                ...newState,
                document: defaultFail(newState.document, load.error(action.payload?.message))
            }
        case UPDATE_DOC_REQUEST:
            return {
                ...newState,
                document: defaultStart(newState.document, update.start)
            }
        case UPDATE_DOC_SUCCESS:
            return {
                ...newState,
                document: defaultSuccess(newState.document, update.success, "documents", [...newDocuments, action.payload.document])
            }
        case UPDATE_DOC_FAIL:
            return {
                ...newState,
                document: defaultFail(newState.document, update.error(action.payload?.message))
            }
        case DELETE_DOC_REQUEST:
            return {
                ...newState,
                document: defaultStart(newState.document, _delete.start)
            }
        case DELETE_DOC_SUCCESS:
            return {
                ...newState,
                document: defaultSuccess(newState.document, _delete.success)
            }
        case DELETE_DOC_FAIL:
            return {
                ...newState,
                document: {
                    ...defaultFail(newState.document, _delete.error(action.payload?.message)), documents: newDocuments.filter(doc => doc._id !== action.payload._deleted_id)
                }
            }
        case CREATE_DOC_TITLE_REQUEST:
            return {
                ...newState,
                title: defaultStart(newState.title, create.start)
            }
        case CREATE_DOC_TITLE_SUCCESS:
            return {
                ...newState,
                title: defaultSuccess(newState.title, create.success, "titles", action.payload.titles)
            }
        case CREATE_DOC_TITLE_FAIL:
            return {
                ...newState,
                title: defaultFail(newState.title, create.error(action.payload?.message))
            }
        case READ_DOC_TITLE_REQUEST:
            return {
                ...newState,
                title: defaultStart(newState.title, load.start)
            }
        case READ_DOC_TITLE_SUCCESS:
            return {
                ...newState,
                title: defaultSuccess(newState.title, load.success, "titles", action.payload.titles)
            }
        case READ_DOC_TITLE_FAIL:
            return {
                ...newState,
                title: defaultFail(newState.title, load.error(action.payload?.message))
            }
        case UPDATE_DOC_TITLE_REQUEST:
            return {
                ...newState,
                title: defaultStart(newState.title, update.start)
            }
        case UPDATE_DOC_TITLE_SUCCESS:
            return {
                ...newState,
                title: defaultSuccess(newState.title, update.success, "titles", action.payload.titles)
            }
        case UPDATE_DOC_TITLE_FAIL:
            return {
                ...newState,
                title: defaultFail(newState.title, update.error(action.payload?.message))
            }
        case CREATE_FOLDER_REQUEST:
            return {
                ...newState,
                folder: defaultStart(newState.folder, create.start)
            }
        case CREATE_FOLDER_SUCCESS:
            for (const folder of action.payload.folders) {
                _folders[folder._id] = folder
                _items[folder._id] = action.payload.items.filter(item => item._folder_id === folder._id)
                _access[folder._id] = action.payload.access.filter(a => a._folder_id === folder._id)
            }
            return {
                ...newState,
                folder: {
                    ...newState.folder,
                    ...create.success,
                    folders: _folders,
                    items: _items,
                    access: _access
                }
            }
        case CREATE_FOLDER_FAIL:
            return {
                ...newState,
                folder: defaultFail(newState.folder, create.error(action.payload?.message))
            }
        case READ_FOLDER_REQUEST:
            return {
                ...newState,
                folder: defaultStart(newState.folder, load.start)
            }
        case READ_FOLDER_SUCCESS:
            for (const folder of action.payload.folders) {
                _folders[folder._id] = folder
                _items[folder._id] = action.payload.items.filter(item => item._folder_id === folder._id)
                _access[folder._id] = action.payload.access.filter(a => a._folder_id === folder._id)
            }
            return {
                ...newState,
                folder: {
                    ...newState.folder,
                    ...load.success,
                    folders: _folders,
                    items: _items,
                    access: _access
                }
            }
        case READ_FOLDER_FAIL:
            return {
                ...newState,
                folder: defaultFail(newState.folder, load.error(action.payload?.message))
            }
        case UPDATE_FOLDER_REQUEST:
            return {
                ...newState,
                folder: defaultStart(newState.folder, update.start)
            }
        case UPDATE_FOLDER_SUCCESS:
            for (const folder of action.payload.folders) {
                _folders[folder._id] = folder
                _items[folder._id] = action.payload.items.filter(item => item._folder_id === folder._id)
                _access[folder._id] = action.payload.access.filter(a => a._folder_id === folder._id)
            }
            return {
                ...newState,
                folder: {
                    ...newState.folder,
                    ...update.success,
                    folders: _folders,
                    items: _items,
                    access: _access
                }
            }
        case UPDATE_FOLDER_FAIL:
            return {
                ...newState,
                folder: defaultFail(newState.folder, update.error(action.payload?.message))
            }
        case DELETE_FOLDER_REQUEST:
            return {
                ...newState,
                folder: defaultStart(newState.folder, _delete.start)
            }
        case DELETE_FOLDER_SUCCESS:
            newState.folder.folders[action.payload._id] = null
            newState.folder.items[action.payload._id] = null
            newState.folder.access[action.payload._id] = null
            return {
                ...newState,
                folder: {
                    ...newState.folder,
                    ..._delete.success,
                    folders: newState.folder.folders,
                    items: newState.folder.items,
                    access: newState.folder.access
                }
            }
        case DELETE_FOLDER_FAIL:
            return {
                ...newState,
                folder: defaultFail(newState.folder, _delete.error(action.payload?.message))
            }
        default:
            return { ...state }
    }
}

export default LeavesReducer