export const CREATE_ASSET_REQUEST = "CREATE_ASSET_REQUEST"
export const CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS"
export const CREATE_ASSET_FAIL = "CREATE_ASSET_FAIL"
export const READ_ASSET_REQUEST = "READ_ASSET_REQUEST"
export const READ_ASSET_SUCCESS = "READ_ASSET_SUCCESS"
export const READ_ASSET_FAIL = "READ_ASSET_FAIL"
export const UPDATE_ASSET_REQUEST = "UPDATE_ASSET_REQUEST"
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS"
export const UPDATE_ASSET_FAIL = "UPDATE_ASSET_FAIL"
export const DELETE_ASSET_REQUEST = "DELETE_ASSET_REQUEST"
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS"
export const DELETE_ASSET_FAIL = "DELETE_ASSET_FAIL"
export const CREATE_SERVICERS_REQUEST = "CREATE_SERVICERS_REQUEST"
export const CREATE_SERVICERS_SUCCESS = "CREATE_SERVICERS_SUCCESS"
export const CREATE_SERVICERS_FAIL = "CREATE_SERVICERS_FAIL"
export const READ_SERVICERS_REQUEST = "READ_SERVICERS_REQUEST"
export const READ_SERVICERS_SUCCESS = "READ_SERVICERS_SUCCESS"
export const READ_SERVICERS_FAIL = "READ_SERVICERS_FAIL"
export const UPDATE_SERVICERS_REQUEST = "UPDATE_SERVICERS_REQUEST"
export const UPDATE_SERVICERS_SUCCESS = "UPDATE_SERVICERS_SUCCESS"
export const UPDATE_SERVICERS_FAIL = "UPDATE_SERVICERS_FAIL"
export const DELETE_SERVICERS_REQUEST = "DELETE_SERVICERS_REQUEST"
export const DELETE_SERVICERS_SUCCESS = "DELETE_SERVICERS_SUCCESS"
export const DELETE_SERVICERS_FAIL = "DELETE_SERVICERS_FAIL"
export const CREATE_SUPPLIERS_REQUEST = "CREATE_SUPPLIERS_REQUEST"
export const CREATE_SUPPLIERS_SUCCESS = "CREATE_SUPPLIERS_SUCCESS"
export const CREATE_SUPPLIERS_FAIL = "CREATE_SUPPLIERS_FAIL"
export const READ_SUPPLIERS_REQUEST = "READ_SUPPLIERS_REQUEST"
export const READ_SUPPLIERS_SUCCESS = "READ_SUPPLIERS_SUCCESS"
export const READ_SUPPLIERS_FAIL = "READ_SUPPLIERS_FAIL"
export const UPDATE_SUPPLIERS_REQUEST = "UPDATE_SUPPLIERS_REQUEST"
export const UPDATE_SUPPLIERS_SUCCESS = "UPDATE_SUPPLIERS_SUCCESS"
export const UPDATE_SUPPLIERS_FAIL = "UPDATE_SUPPLIERS_FAIL"
export const DELETE_SUPPLIERS_REQUEST = "DELETE_SUPPLIERS_REQUEST"
export const DELETE_SUPPLIERS_SUCCESS = "DELETE_SUPPLIERS_SUCCESS"
export const DELETE_SUPPLIERS_FAIL = "DELETE_SUPPLIERS_FAIL"
export const CREATE_SERV_HIST_REQUEST = "CREATE_SERV_HIST_REQUEST"
export const CREATE_SERV_HIST_SUCCESS = "CREATE_SERV_HIST_SUCCESS"
export const CREATE_SERV_HIST_FAIL = "CREATE_SERV_HIST_FAIL"
export const READ_SERV_HIST_REQUEST = "READ_SERV_HIST_REQUEST"
export const READ_SERV_HIST_SUCCESS = "READ_SERV_HIST_SUCCESS"
export const READ_SERV_HIST_FAIL = "READ_SERV_HIST_FAIL"
export const CREATE_INVOICES_REQUEST = "CREATE_INVOICES_REQUEST"
export const CREATE_INVOICES_SUCCESS = "CREATE_INVOICES_SUCCESS"
export const CREATE_INVOICES_FAIL = "CREATE_INVOICES_FAIL"
export const READ_INVOICES_REQUEST = "READ_INVOICES_REQUEST"
export const READ_INVOICES_SUCCESS = "READ_INVOICES_SUCCESS"
export const READ_INVOICES_FAIL = "READ_INVOICES_FAIL"
export const CREATE_OWNER_HIST_REQUEST = "CREATE_OWNER_HIST_REQUEST"
export const CREATE_OWNER_HIST_SUCCESS = "CREATE_OWNER_HIST_SUCCESS"
export const CREATE_OWNER_HIST_FAIL = "CREATE_OWNER_HIST_FAIL"
export const READ_OWNER_HIST_REQUEST = "READ_OWNER_HIST_REQUEST"
export const READ_OWNER_HIST_SUCCESS = "READ_OWNER_HIST_SUCCESS"
export const READ_OWNER_HIST_FAIL = "READ_OWNER_HIST_FAIL"
export const UPDATE_OWNER_HIST_REQUEST = "UPDATE_OWNER_HIST_REQUEST"
export const UPDATE_OWNER_HIST_SUCCESS = "UPDATE_OWNER_HIST_SUCCESS"
export const UPDATE_OWNER_HIST_FAIL = "UPDATE_OWNER_HIST_FAIL"
export const CREATE_LOC_HIST_REQUEST = "CREATE_LOC_HIST_REQUEST"
export const CREATE_LOC_HIST_SUCCESS = "CREATE_LOC_HIST_SUCCESS"
export const CREATE_LOC_HIST_FAIL = "CREATE_LOC_HIST_FAIL"
export const READ_LOC_HIST_REQUEST = "READ_LOC_HIST_REQUEST"
export const READ_LOC_HIST_SUCCESS = "READ_LOC_HIST_SUCCESS"
export const READ_LOC_HIST_FAIL = "READ_LOC_HIST_FAIL"
export const UPDATE_LOC_HIST_REQUEST = "UPDATE_LOC_HIST_REQUEST"
export const UPDATE_LOC_HIST_SUCCESS = "UPDATE_LOC_HIST_SUCCESS"
export const UPDATE_LOC_HIST_FAIL = "UPDATE_LOC_HIST_FAIL"
export const CREATE_ASSET_CAT_REQUEST = "CREATE_ASSET_CAT_REQUEST"
export const CREATE_ASSET_CAT_SUCCESS = "CREATE_ASSET_CAT_SUCCESS"
export const CREATE_ASSET_CAT_FAIL = "CREATE_ASSET_CAT_FAIL"
export const UPDATE_ASSET_CAT_REQUEST = "UPDATE_ASSET_CAT_REQUEST"
export const UPDATE_ASSET_CAT_SUCCESS = "UPDATE_ASSET_CAT_SUCCESS"
export const UPDATE_ASSET_CAT_FAIL = "UPDATE_ASSET_CAT_FAIL"
export const READ_ASSET_CAT_REQUEST = "READ_ASSET_CAT_REQUEST"
export const READ_ASSET_CAT_SUCCESS = "READ_ASSET_CAT_SUCCESS"
export const READ_ASSET_CAT_FAIL = "READ_ASSET_CAT_FAIL"
export const CREATE_ISSUE_REPORT_REQUEST = "CREATE_ISSUE_REPORT_REQUEST"
export const CREATE_ISSUE_REPORT_SUCCESS = "CREATE_ISSUE_REPORT_SUCCESS"
export const CREATE_ISSUE_REPORT_FAIL = "CREATE_ISSUE_REPORT_FAIL"
export const READ_ISSUE_REPORT_REQUEST = "READ_ISSUE_REPORT_REQUEST"
export const READ_ISSUE_REPORT_SUCCESS = "READ_ISSUE_REPORT_SUCCESS"
export const READ_ISSUE_REPORT_FAIL = "READ_ISSUE_REPORT_FAIL"
export const UPDATE_ISSUE_REPORT_REQUEST = "UPDATE_ISSUE_REPORT_REQUEST"
export const UPDATE_ISSUE_REPORT_SUCCESS = "UPDATE_ISSUE_REPORT_SUCCESS"
export const UPDATE_ISSUE_REPORT_FAIL = "UPDATE_ISSUE_REPORT_FAIL"


export const CREATE_CONFIRMATION_REQUEST = "CREATE_CONFIRMATION_REQUEST"
export const CREATE_CONFIRMATION_SUCCESS = "CREATE_CONFIRMATION_SUCCESS"
export const CREATE_CONFIRMATION_FAIL = "CREATE_CONFIRMATION_FAIL"
export const READ_CONFIRMATION_REQUEST = "READ_CONFIRMATION_REQUEST"
export const READ_CONFIRMATION_SUCCESS = "READ_CONFIRMATION_SUCCESS"
export const READ_CONFIRMATION_FAIL = "READ_CONFIRMATION_FAIL"
export const UPDATE_CONFIRMATION_REQUEST = "UPDATE_CONFIRMATION_REQUEST"
export const UPDATE_CONFIRMATION_SUCCESS = "UPDATE_CONFIRMATION_SUCCESS"
export const UPDATE_CONFIRMATION_FAIL = "UPDATE_CONFIRMATION_FAIL"


export const CREATE_ASSET_KIT_REQUEST = "CREATE_ASSET_KIT_REQUEST"
export const CREATE_ASSET_KIT_SUCCESS = "CREATE_ASSET_KIT_SUCCESS"
export const CREATE_ASSET_KIT_FAILURE = "CREATE_ASSET_KIT_FAILURE"
export const READ_ASSET_KIT_REQUEST = "READ_ASSET_KIT_REQUEST"
export const READ_ASSET_KIT_SUCCESS = "READ_ASSET_KIT_SUCCESS"
export const READ_ASSET_KIT_FAILURE = "READ_ASSET_KIT_FAILURE"
export const UPDATE_ASSET_KIT_REQUEST = "UPDATE_ASSET_KIT_REQUEST"
export const UPDATE_ASSET_KIT_SUCCESS = "UPDATE_ASSET_KIT_SUCCESS"
export const UPDATE_ASSET_KIT_FAILURE = "UPDATE_ASSET_KIT_FAILURE"
export const DELETE_ASSET_KIT_REQUEST = "DELETE_ASSET_KIT_REQUEST"
export const DELETE_ASSET_KIT_SUCCESS = "DELETE_ASSET_KIT_SUCCESS"
export const DELETE_ASSET_KIT_FAILURE = "DELETE_ASSET_KIT_FAILURE"


export const COPY_ASSET_KIT_REQUEST = "COPY_ASSET_KIT_REQUEST"
export const COPY_ASSET_KIT_SUCCESS = "COPY_ASSET_KIT_SUCCESS"
export const COPY_ASSET_KIT_FAILURE = "COPY_ASSET_KIT_FAILURE"