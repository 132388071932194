import {
    READ_SHOP_PRODUCTS_REQUEST,
    READ_SHOP_PRODUCTS_SUCCESS,
    READ_SHOP_PRODUCTS_FAILURE,
    CREATE_SHOP_ORDERS_REQUEST,
    CREATE_SHOP_ORDERS_SUCCESS,
    CREATE_SHOP_ORDERS_FAILURE,
    READ_SHOP_ORDERS_REQUEST,
    READ_SHOP_ORDERS_SUCCESS,
    READ_SHOP_ORDERS_FAILURE,
    UPDATE_SHOP_ORDERS_REQUEST,
    UPDATE_SHOP_ORDERS_SUCCESS,
    UPDATE_SHOP_ORDERS_FAILURE,
    READ_SHOP_DATA_REQUEST,
    READ_SHOP_DATA_SUCCESS,
    READ_SHOP_DATA_FAILURE,
    READ_TECH_PACKAGES_REQUEST,
    READ_TECH_PACKAGES_SUCCESS,
    READ_TECH_PACKAGES_FAILURE,
    READ_ORDER_REQUEST,
    READ_ORDER_SUCCESS,
    READ_ORDER_FAILURE,
    READ_PACKAGE_REQUEST,
    READ_PACKAGE_SUCCESS,
    READ_PACKAGE_FAILURE
} from "./constants"

import { load, create, update, defaultFail, defaultStart, defaultSuccess } from "../AsyncStatuses"

const CentralShop = {
    product: {
        products: [],
        all_products: [],
        ...load.idle,
    },

    customerOrders: {
        orders: [],
        ...load.idle
    },

    package: {
        packages: [],
        ...load.idle,
    },

    productPackages: {
        packages: [],
        ...load.idle,
    },

    order: {
        orders: [],
        allOrders: [],
        ...load.idle,
        ...create.idle,
        ...update.idle,
    },
    data: {
        _product_categories: [],
        _order_statuses: [],
        _user_types: [],
        _instances: [],
        _currencies: [],

        ...load.idle,
    }
}

const CentralShopReducer = (state = CentralShop, action) => {
    const newState = { ...state }
    let packages = []
    switch (action.type) {

        case READ_ORDER_REQUEST: {
            return {
                ...state,
                customerOrders: {
                    ...state.customerOrders,
                    ...load.start
                }
            }
        }
        case READ_ORDER_SUCCESS: {
            const _orders = {}
            for (const ord of action.payload._customer_orders) {
                if (_orders[ord._order_number] === undefined) _orders[ord._order_number] = []
                _orders[ord._order_number].push(ord)
            }
            return {
                ...state,
                customerOrders: {
                    ...state.customerOrders,
                    ...load.success,
                    orders: _orders
                }
            }
        }
        case READ_ORDER_FAILURE: {
            return {
                ...state,
                customerOrders: {
                    ...state.customerOrders,
                    ...load.error(action.payload.message),
                }
            }
        }
        case READ_PACKAGE_REQUEST: {
            return {
                ...state,
                productPackages: {
                    ...state.productPackages,
                    ...load.start,
                }
            }
        }
        case READ_PACKAGE_SUCCESS: {
            const _pkg = []
            for (const pack of action.payload.packages) {
                _pkg[pack._id] = pack
            }
            return {
                ...state,
                productPackages: {
                    ...state.productPackages,
                    ...load.success,
                    packages: _pkg
                }
            }
        }
        case READ_PACKAGE_FAILURE: {
            return {
                ...state,
                productPackages: {
                    ...state.productPackages,
                    ...load.error(action.payload.message),
                }
            }
        }

        case READ_TECH_PACKAGES_REQUEST:
            return {
                ...state,
                package: {
                    ...load.start
                }
            }
        case READ_TECH_PACKAGES_SUCCESS:
            packages = []
            for (const pkg of action.payload.packages) {
                packages[pkg._id] = {
                    ...pkg,
                    items: action.payload.package_items.filter(item => item._package_id === pkg._id),
                    locations: action.payload.locations_available.filter(i => i._package_id === pkg._id).map(l => l._instance_id)
                }
            }
            return {
                ...state,
                package: {
                    ...load.success,
                    packages: packages
                }
            }
        case READ_TECH_PACKAGES_FAILURE:
            return {
                ...state,
                package: {
                    ...load.error,
                    errorMessage: action.payload?.message
                }
            }

        case READ_SHOP_DATA_REQUEST:
            return {
                ...newState, data: defaultStart(newState.data, load.start)
            }
        case READ_SHOP_DATA_SUCCESS:
            return {
                ...newState,
                data: {
                    ...load.success,
                    _product_categories: action.payload._product_categories,
                    _order_statuses: action.payload._order_statuses,
                    _user_types: action.payload._user_types,
                    _instances: action.payload._instances,
                    _currencies: action.payload._currencies
                }
            }
        case READ_SHOP_DATA_FAILURE:
            return {
                ...newState, data: defaultFail(newState.data, load.error(action.payload?.message))
            }
        case READ_SHOP_PRODUCTS_REQUEST:
            return {
                ...newState, product: defaultStart(newState.product, load.start)
            }
        case READ_SHOP_PRODUCTS_SUCCESS:
            const _all_products = []
            const _products = []
            for (const product of action.payload?.products) { _products[product._id] = product }
            for (const prod of action.payload?.all_products) { _all_products[prod._id] = prod }
            return {
                ...newState, product: defaultSuccess(defaultSuccess(newState.product, load.success, "all_products", _all_products), load.success, "products", _products)
            }
        case READ_SHOP_PRODUCTS_FAILURE:
            return {
                ...newState, product: defaultFail(newState.product, load.error(action.payload?.message))
            }
        case CREATE_SHOP_ORDERS_REQUEST:
            return {
                ...newState, order: defaultStart(newState.order, create.start)
            }
        case CREATE_SHOP_ORDERS_SUCCESS:
            const t_orders = {}
            for (const order of action.payload._technician_orders) {
                if (t_orders[order._order_number] === undefined) t_orders[order._order_number] = []
                t_orders[order._order_number].push(order)
            }
            return {
                ...newState, order: defaultSuccess(newState.order, create.success, "orders", action.payload.t_orders)
            }
        case CREATE_SHOP_ORDERS_FAILURE:
            return {
                ...newState, order: defaultFail(newState.order, create.error(action.payload?.message))
            }
        case READ_SHOP_ORDERS_REQUEST:
            return {
                ...newState, order: defaultStart(newState.order, load.start)
            }
        case READ_SHOP_ORDERS_SUCCESS:
            const _t_orders = {}
            for (const order of action.payload._technician_orders) {
                if (_t_orders[order._order_number] === undefined) _t_orders[order._order_number] = []
                _t_orders[order._order_number].push(order)
            }
            return {
                ...newState, order: {
                    ...defaultSuccess(newState.order, load.success, "orders", _t_orders),
                    allOrders: action.payload._technician_orders
                }
            }
        case READ_SHOP_ORDERS_FAILURE:
            return {
                ...newState, order: defaultFail(newState.order, load.error(action.payload?.message))
            }
        case UPDATE_SHOP_ORDERS_REQUEST:
            return {
                ...newState, order: defaultStart(newState.order, update.start)
            }
        case UPDATE_SHOP_ORDERS_SUCCESS:
            const _orders = {}
            for (const order of action.payload.orders) {
                if (_orders[order._order_number] === undefined) _orders[order._order_number] = []
                _orders[order._order_number].push(order)
            }
            return {
                ...newState, order: defaultSuccess(newState.order, update.success, "orders", _orders)
            }
        case UPDATE_SHOP_ORDERS_FAILURE:
            return { ...newState, order: defaultFail(newState.order, update.error(action.payload?.message)) }
        default:
            return { ...state }
    }
}

export default CentralShopReducer