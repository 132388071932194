export const CREATE_PART_TYPE_REQUEST = "CREATE_PART_TYPE_REQUEST"
export const CREATE_PART_TYPE_SUCCESS = "CREATE_PART_TYPE_SUCCESS"
export const CREATE_PART_TYPE_FAILURE = "CREATE_PART_TYPE_FAILURE"
export const READ_PART_TYPE_REQUEST = "READ_PART_TYPE_REQUEST"
export const READ_PART_TYPE_SUCCESS = "READ_PART_TYPE_SUCCESS"
export const READ_PART_TYPE_FAILURE = "READ_PART_TYPE_FAILURE"
export const UPDATE_PART_TYPE_REQUEST = "UPDATE_PART_TYPE_REQUEST"
export const UPDATE_PART_TYPE_SUCCESS = "UPDATE_PART_TYPE_SUCCESS"
export const UPDATE_PART_TYPE_FAILURE = "UPDATE_PART_TYPE_FAILURE"
export const DELETE_PART_TYPE_REQUEST = "DELETE_PART_TYPE_REQUEST"
export const DELETE_PART_TYPE_SUCCESS = "DELETE_PART_TYPE_SUCCESS"
export const DELETE_PART_TYPE_FAILURE = "DELETE_PART_TYPE_FAILURE"


export const CREATE_MAKE_REQUEST = "CREATE_MAKE_REQUEST"
export const CREATE_MAKE_SUCCESS = "CREATE_MAKE_SUCCESS"
export const CREATE_MAKE_FAILURE = "CREATE_MAKE_FAILURE"
export const READ_MAKE_REQUEST = "READ_MAKE_REQUEST"
export const READ_MAKE_SUCCESS = "READ_MAKE_SUCCESS"
export const READ_MAKE_FAILURE = "READ_MAKE_FAILURE"
export const UPDATE_MAKE_REQUEST = "UPDATE_MAKE_REQUEST"
export const UPDATE_MAKE_SUCCESS = "UPDATE_MAKE_SUCCESS"
export const UPDATE_MAKE_FAILURE = "UPDATE_MAKE_FAILURE"
export const DELETE_MAKE_REQUEST = "DELETE_MAKE_REQUEST"
export const DELETE_MAKE_SUCCESS = "DELETE_MAKE_SUCCESS"
export const DELETE_MAKE_FAILURE = "DELETE_MAKE_FAILURE"


export const CREATE_MODEL_REQUEST = "CREATE_MODEL_REQUEST"
export const CREATE_MODEL_SUCCESS = "CREATE_MODEL_SUCCESS"
export const CREATE_MODEL_FAILURE = "CREATE_MODEL_FAILURE"
export const READ_MODEL_REQUEST = "READ_MODEL_REQUEST"
export const READ_MODEL_SUCCESS = "READ_MODEL_SUCCESS"
export const READ_MODEL_FAILURE = "READ_MODEL_FAILURE"
export const UPDATE_MODEL_REQUEST = "UPDATE_MODEL_REQUEST"
export const UPDATE_MODEL_SUCCESS = "UPDATE_MODEL_SUCCESS"
export const UPDATE_MODEL_FAILURE = "UPDATE_MODEL_FAILURE"
export const DELETE_MODEL_REQUEST = "DELETE_MODEL_REQUEST"
export const DELETE_MODEL_SUCCESS = "DELETE_MODEL_SUCCESS"
export const DELETE_MODEL_FAILURE = "DELETE_MODEL_FAILURE"


export const CREATE_BODY_TYPE_REQUEST = "CREATE_BODY_TYPE_REQUEST"
export const CREATE_BODY_TYPE_SUCCESS = "CREATE_BODY_TYPE_SUCCESS"
export const CREATE_BODY_TYPE_FAILURE = "CREATE_BODY_TYPE_FAILURE"
export const READ_BODY_TYPE_REQUEST = "READ_BODY_TYPE_REQUEST"
export const READ_BODY_TYPE_SUCCESS = "READ_BODY_TYPE_SUCCESS"
export const READ_BODY_TYPE_FAILURE = "READ_BODY_TYPE_FAILURE"
export const UPDATE_BODY_TYPE_REQUEST = "UPDATE_BODY_TYPE_REQUEST"
export const UPDATE_BODY_TYPE_SUCCESS = "UPDATE_BODY_TYPE_SUCCESS"
export const UPDATE_BODY_TYPE_FAILURE = "UPDATE_BODY_TYPE_FAILURE"
export const DELETE_BODY_TYPE_REQUEST = "DELETE_BODY_TYPE_REQUEST"
export const DELETE_BODY_TYPE_SUCCESS = "DELETE_BODY_TYPE_SUCCESS"
export const DELETE_BODY_TYPE_FAILURE = "DELETE_BODY_TYPE_FAILURE"



export const CREATE_ISO_REQUEST = "CREATE_ISO_REQUEST"
export const CREATE_ISO_SUCCESS = "CREATE_ISO_SUCCESS"
export const CREATE_ISO_FAILURE = "CREATE_ISO_FAILURE"
export const READ_ISO_REQUEST = "READ_ISO_REQUEST"
export const READ_ISO_SUCCESS = "READ_ISO_SUCCESS"
export const READ_ISO_FAILURE = "READ_ISO_FAILURE"
export const UPDATE_ISO_REQUEST = "UPDATE_ISO_REQUEST"
export const UPDATE_ISO_SUCCESS = "UPDATE_ISO_SUCCESS"
export const UPDATE_ISO_FAILURE = "UPDATE_ISO_FAILURE"


export const CREATE_ISO_TYPE_REQUEST = "CREATE_ISO_TYPE_REQUEST"
export const CREATE_ISO_TYPE_SUCCESS = "CREATE_ISO_TYPE_SUCCESS"
export const CREATE_ISO_TYPE_FAILURE = "CREATE_ISO_TYPE_FAILURE"
export const READ_ISO_TYPE_REQUEST = "READ_ISO_TYPE_REQUEST"
export const READ_ISO_TYPE_SUCCESS = "READ_ISO_TYPE_SUCCESS"
export const READ_ISO_TYPE_FAILURE = "READ_ISO_TYPE_FAILURE"
export const UPDATE_ISO_TYPE_REQUEST = "UPDATE_ISO_TYPE_REQUEST"
export const UPDATE_ISO_TYPE_SUCCESS = "UPDATE_ISO_TYPE_SUCCESS"
export const UPDATE_ISO_TYPE_FAILURE = "UPDATE_ISO_TYPE_FAILURE"