import {
    READ_VIDEOS_REQUEST,
    READ_VIDEOS_SUCCESS,
    READ_VIDEOS_FAIL,
    CREATE_VIDEOS_REQUEST,
    CREATE_VIDEOS_SUCCESS,
    CREATE_VIDEOS_FAIL,
    UPDATE_VIDEOS_REQUEST,
    UPDATE_VIDEOS_SUCCESS,
    UPDATE_VIDEOS_FAIL,
    DELETE_VIDEOS_REQUEST,
    DELETE_VIDEOS_SUCCESS,
    DELETE_VIDEOS_FAIL,
    READ_ASSIGNMENTS_REQUEST,
    READ_ASSIGNMENTS_SUCCESS,
    READ_ASSIGNMENTS_FAIL,
    CREATE_ASSIGNMENTS_REQUEST,
    CREATE_ASSIGNMENTS_SUCCESS,
    CREATE_ASSIGNMENTS_FAIL,
    UPDATE_ASSIGNMENTS_REQUEST,
    UPDATE_ASSIGNMENTS_SUCCESS,
    UPDATE_ASSIGNMENTS_FAIL,
    DELETE_ASSIGNMENTS_REQUEST,
    DELETE_ASSIGNMENTS_SUCCESS,
    DELETE_ASSIGNMENTS_FAIL
} from "./constants"

const videos = {
    video: {
        videos: [],
        categories: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
        deleteStatus: "",
        deleteMessage: "",
    },
    assignment: {
        assignments: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
        deleteStatus: "",
        deleteMessage: "",
    }
}

const VideosReducer = (state = videos, action) => {
    let vids = []
    let assignments = []
    switch (action.type) {
        case READ_VIDEOS_REQUEST:
            return {
                ...state,
                video: {
                    ...state.video,
                    readStatus: "pending",
                    readMessage: "Loading videos... Please wait",
                }
            }
        case READ_VIDEOS_SUCCESS:
            for (const video of action.payload.videos) vids[video._id] = video
            return {
                ...state,
                video: {
                    ...state.video,
                    readStatus: "success",
                    readMessage: "Videos loaded.",
                    videos: vids,
                    categories: action.payload.categories
                }
            }
        case READ_VIDEOS_FAIL:
            return {
                ...state,
                video: {
                    ...state.video,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_VIDEOS_REQUEST:
            return {
                ...state,
                video: {
                    ...state.video,
                    createStatus: "pending",
                    createMessage: "Creating video... Please wait",
                }
            }
        case CREATE_VIDEOS_SUCCESS:
            vids = [...state.video.videos]
            vids[action.payload.video._id] = action.payload.video
            return {
                ...state,
                video: {
                    ...state.video,
                    createStatus: "success",
                    createMessage: "Video created.",
                    videos: vids
                }
            }
        case CREATE_VIDEOS_FAIL:
            return {
                ...state,
                video: {
                    ...state.video,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_VIDEOS_REQUEST:
            return {
                ...state,
                video: {
                    ...state.video,
                    updateStatus: "pending",
                    updateMessage: "Updating video... Please wait",
                }
            }
        case UPDATE_VIDEOS_SUCCESS:
            vids = [...state.video.videos]
            vids[action.payload.video._id] = action.payload.video
            return {
                ...state,
                video: {
                    ...state.video,
                    updateStatus: "success",
                    updateMessage: "Video updated.",
                    videos: vids
                }
            }
        case UPDATE_VIDEOS_FAIL:
            return {
                ...state,
                video: {
                    ...state.video,
                    updateStatus: "error",
                    updateMessage: action.payload?.message
                }
            }
        case DELETE_VIDEOS_REQUEST:
            return {
                ...state,
                video: {
                    ...state.video,
                    deleteStatus: "pending",
                    deleteMessage: "Deleting video... Please wait.",
                }
            }
        case DELETE_VIDEOS_SUCCESS:
            vids = [...state.video.videos]
            vids[action.payload.video._id] = action.payload.video
            return {
                ...state,
                video: {
                    ...state.video,
                    deleteStatus: "success",
                    deleteMessage: "Video deleted.",
                    videos: vids
                }
            }
        case DELETE_VIDEOS_FAIL:
            return {
                ...state,
                video: {
                    ...state.video,
                    deleteStatus: "error",
                    deleteMessage: action.payload?.message
                }
            }
        case READ_ASSIGNMENTS_REQUEST:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    readStatus: "pending",
                    readMessage: "Loading assignments... Please wait.",
                }
            }
        case READ_ASSIGNMENTS_SUCCESS:
            assignments = []
            for (const assignment of action.payload.assignments) assignments[assignment._id] = assignment
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    readStatus: "success",
                    readMessage: "Assignments loaded.",
                    assignments: assignments
                }
            }
        case READ_ASSIGNMENTS_FAIL:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_ASSIGNMENTS_REQUEST:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    createStatus: "pending",
                    createMessage: "Creating assignments... Please wait.",
                }
            }
        case CREATE_ASSIGNMENTS_SUCCESS:
            assignments = [...state.assignment.assignments]
            assignments[action.payload.assignment._id] = action.payload.assignment
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    createStatus: "success",
                    createMessage: "Assignments created.",
                    assignments: assignments
                }
            }
        case CREATE_ASSIGNMENTS_FAIL:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_ASSIGNMENTS_REQUEST:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    updateStatus: "pending",
                    updateMessage: "Updating assignments... Please wait.",
                }
            }
        case UPDATE_ASSIGNMENTS_SUCCESS:
            assignments = [...state.assignment.assignments]
            assignments[action.payload.assignment._id] = action.payload.assignment
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    updateStatus: "success",
                    updateMessage: "Assignments updated.",
                    assignments: assignments
                }
            }
        case UPDATE_ASSIGNMENTS_FAIL:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    updateStatus: "error",
                    updateMessage: action.payload?.message,
                }
            }
        case DELETE_ASSIGNMENTS_REQUEST:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    deleteStatus: "pending",
                    deleteMessage: "Deleting assignments... Please wait.",
                }
            }
        case DELETE_ASSIGNMENTS_SUCCESS:
            assignments = [...state.assignment.assignments]
            assignments[action.payload._id] = null
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    deleteStatus: "success",
                    deleteMessage: "Assignments deleted.",
                    assignments: assignments
                }
            }
        case DELETE_ASSIGNMENTS_FAIL:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    deleteStatus: "error",
                    deleteMessage: action.payload?.message,
                }
            }
        default:
            return { ...state };
    }
}

export default VideosReducer